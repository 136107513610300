import { injectSlice } from '@common/configs/redux/InjectSlice';
import {
  ajaxBaseQuery,
  type AjaxBaseQueryFn,
  type AjaxBaseQueryOptions
} from '@common/libs/ajax/AjaxBaseQuery';
import { createSlice } from '@reduxjs/toolkit';
import type { ReactHooksModule } from '@reduxjs/toolkit/dist/query/react/module';
import {
  createApi,
  type Api,
  type BaseQueryFn,
  type CoreModule,
  type CreateApiOptions,
  type EndpointDefinitions
} from '@reduxjs/toolkit/query/react';
import { isFunction } from 'lodash';

// First overload specific to AjaxBaseQueryFn and AjaxBaseQueryOptions
export function createLazyInjectedApi<
  Definitions extends EndpointDefinitions,
  ReducerPath extends string,
  TagTypes extends string = never,
>(options: Override<CreateApiOptions<AjaxBaseQueryFn, Definitions, ReducerPath, TagTypes>, {
    reducerPath: ReducerPath;
    baseQuery?: AjaxBaseQueryOptions;
  }>
): Api<AjaxBaseQueryFn, Definitions, ReducerPath, TagTypes, CoreModule | ReactHooksModule>;

// Second overload specific to the default BaseQueryFn
export function createLazyInjectedApi<
  BaseQuery extends BaseQueryFn,
  Definitions extends EndpointDefinitions,
  ReducerPath extends string,
  TagTypes extends string = never,
>(options: Override<CreateApiOptions<BaseQuery, Definitions, ReducerPath, TagTypes>, {
    reducerPath: ReducerPath;
    baseQuery: BaseQuery;
  }>
): Api<BaseQuery, Definitions, ReducerPath, TagTypes, CoreModule | ReactHooksModule>;

// Implementation
export function createLazyInjectedApi<
  Definitions extends EndpointDefinitions,
  ReducerPath extends string,
  BaseQuery extends BaseQueryFn,
  TagTypes extends string = never,
>(options: Override<CreateApiOptions<BaseQuery | AjaxBaseQueryFn, Definitions, ReducerPath, TagTypes>, {
    reducerPath: ReducerPath;
    baseQuery?: BaseQuery | AjaxBaseQueryOptions;
  }>): Api<BaseQuery | AjaxBaseQueryFn, Definitions, ReducerPath, TagTypes, CoreModule | ReactHooksModule> {
  const {
    baseQuery: baseQueryFn,
    ...restOptions
  } = options;

  if (restOptions.reducerPath == null) {
    throw new Error('reducerPath is required');
  }

  const baseQuery = isFunction(baseQueryFn) ? baseQueryFn : ajaxBaseQuery(baseQueryFn);

  const apiService = createApi({
    baseQuery,
    ...restOptions
  });

  injectSlice(apiService);

  return apiService;
}

export const createLazyInjectedSlice: typeof createSlice = (options) => {
  const slice = createSlice(options);
  injectSlice(slice);
  return slice;
};
