const Marionette = require('Marionette');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const I18n = require('@common/libs/I18n');
require('@common/libs/behaviors/card/Card');
const {
  INFORMATIONAL,
  SMALL_PADDING
} = require('@common/libs/behaviors/card/CardModifierClasses');
const { canDownload } = require('@common/libs/helpers/app/NativeBridgeHelpers');
const MediaViewModel = require('@common/components/media/MediaViewModel');

require('@common/components/media/AttachmentItemView.less');

class AttachmentItemView extends Marionette.ItemView {
  behaviors() {
    return {
      Card: {
        target: '.attachment__container',
        modifierClasses: [SMALL_PADDING, INFORMATIONAL]
      }
    };
  }

  constructor(options = {}) {
    super(options);
    ({
      enableDownload: this.enableDownload = true
    } = options);
  }

  triggers() {
    return {
      'click .attachment--viewable': 'click:attachment'
    };
  }

  get template() {
    return require('./AttachmentItemViewTemplate.html');
  }

  isAllowedToDownload() {
    const viewModel = MediaViewModel.fromMedia(this.model);
    const isPDF = (viewModel.getFileType() === 'pdf');
    const learnerZoneAllowPdfDownload = TenantPropertyProvider.get().getProperty('learnerZoneAllowPdfDownload');

    if (isPDF && !learnerZoneAllowPdfDownload) {
      return false; // when the tenant property is off, the user is not allowed to download PDFs.
    }
    return true;
  }

  templateHelpers() {
    const viewModel = MediaViewModel.fromMedia(this.model);
    const uuid = viewModel.getUUID();
    const fileType = viewModel.getFileType();
    const isViewable = (fileType === 'pdf');

    // "canDownload" is a function that determines if the device has the capability of downloading files.
    // enableDownload is a property that is passed in to the view that determines if the download button should be shown, it's a UI choice.
    // isAllowedToDownload is a function that determines if the user is allowed to download the file, based on a tenant property.
    // these are three distinct criteria for showing the download button, and they must all be true.
    const isDownloadable = canDownload() && this.enableDownload && this.isAllowedToDownload();

    const name = viewModel.getName();
    const ariaLabel = I18n.t('attachments.download', { name });

    return {
      id: this.model.get('id'),
      type: fileType,
      icon: viewModel.getIcon(),
      name,
      isViewable,
      isDownloadable,
      uuid,
      ariaLabel
    };
  }
}

module.exports = AttachmentItemView;
