// Module
var code = `<!--QUESTION-->
<div class="hotspot">
  <div class="question-number-header"><%- t('question.questionNumberHeader', { questionOrdinal }) %></div>
  <div class="questionarea clearfix">
    <div class="questionask"><%= variant.questionText %></div>
    <div class="clear"></div>
    <div class="bonuscharacter"><div class="<%- bonuscharacter %> ponder"></div></div>
  </div>
  <div class="hinttext hotspot">
    <div role="heading" aria-level="3" class="question-instruction clearfix"></div>
  </div>
  <!--ANSWERS-->
  <div class="answerwrappersize">
    <div class="answerwrapper">
      <div class="hotspotimagewrapper">
        <% if (variant.questionImg) { %>
          <div class="questionimage" data-media-id="<%- variant.questionImg.preferred.id %>"></div>
        <% } %>
      </div>
    </div>
  </div>
</div>
`;
// Exports
module.exports = code;