// Module
var code = `<div class="tile-top">
	<div class="topic">
    <span class="hidden icon" role="img"></span>
    <h2 class="topic-hierarchy-details" title="<%= t('assessments.item.catSub', {category: category, subject: subject}) %>">
      <%= t('assessments.item.catSub', {category: category, subject: subject}) %>
		</h2>
		<h3 class="topic-name qa-topic-name" title="<%- topic.name %>">
			<%- topic.name %> - <%- t('knowledge.level', {level: level}) %>
		</h3>
	</div>

	<div class="topic-statuses">

		<div class="grade">
			<p class="grade-value"></p>
			<p class="grade-text"></p>
		</div>

		<div class="lock-icon"></div>

	</div>

</div>

<div class="tile-bottom">

	<div class="tile-meta">

		<span class="first content"></span>
		<span class="last qa-due due"></span>
		<span class="last qa-rewards rewards" ></span>

	</div>

	<div class="action-buttons-wrapper">
		<%= axButton({
			className: 'retake-button js-retake-button qa-retake-button hidden',
			ariaLabel: t('assessments.select.retake') + ' ' + topic.name + ' ' + t('knowledge.level', {level: level}),
			label: t('assessments.select.retake'),
			iconClassLeft: 'icon-play',
			branded: true
		}) %>
		<%= axButton({
			className: 'start-next-button js-start-next-button qa-start-next-button hidden',
			ariaLabel: t('assessments.select.startNext') + ' ' + topic.name + ' ' + t('knowledge.level', {level: level}),
			label: t('assessments.select.startNext'),
			iconClassLeft: 'icon-play',
			branded: true
		}) %>
	</div>
</div>

<div class="tile-print-certifications print-certifications-button-wrapper"></div>
`;
// Exports
module.exports = code;