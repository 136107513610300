import {
  AxIcon,
  type AxIconProps
} from '@common/modules/react/themes/components';

export default function PdfIcon(props: AxIconProps) {
  return (
    <AxIcon
      className='icon-pdf'
      { ...props }
    />
  );
}

