import {
  AxIcon,
  AxIconButton,
  AxInputAdornment,
  AxTextField,
  type AxTextFieldProps
} from '@common/modules/react/themes/components';
import {
  styled,
  type TextFieldProps
} from '@mui/material';
import {
  LocalizationProvider,
  DatePicker as MuiDatePicker,
  type PickerValidDate as MuiPickerValidDate
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  forwardRef,
  type Ref
} from 'react';

import I18n from '@common/libs/I18n';
import { createDate } from '@common/libs/dateHelpers';
import { useDateTimeStyles } from '@common/modules/react/themes/components/mui-x/AxDatePicker/useDateTimeStyles';
import { type DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers';

export interface AxDatePickerProps<
  TDate extends MuiPickerValidDate,
  TEnableAccessibleFieldDOMStructure extends boolean = false
> extends MuiDatePickerProps<TDate, TEnableAccessibleFieldDOMStructure> {
  required?: boolean;
  useFormatForTodaysDate?: boolean;
}

const styledOptions = {
  name: 'AxDatePicker'
};

const StyledAxDatePicker = styled(MuiDatePicker, styledOptions)(() => {
  // AxDatePicker uses different components (slots) for the text field and input adornment.
  // The styles are defined within the components.
  return {};
});

interface CustomDatePickerTextFieldProps {
  dateFormat: string;
}

const CustomDatePickerTextField = (props: CustomDatePickerTextFieldProps & AxTextFieldProps) => {
  const {
    value,
    dateFormat,
    ...otherProps
  } = props;
  const isToday = value && createDate(value, dateFormat).isSame(createDate(), 'day');
  return <AxTextField { ...otherProps } value={ isToday ? I18n.t('datePicker.today') : value } />;
};

/**
 * A date picker component that uses the MUI X date picker.
 *
 * #### Links
 * - • [MUI Demo](https://mui.com/x/react-date-pickers/date-picker/)
 * - • [MUI API](https://mui.com/x/api/date-pickers/date-picker/)
 */
export const AxDatePicker = forwardRef(<
  TDate extends MuiPickerValidDate,
  TEnableAccessibleFieldDOMStructure extends boolean = false
> (
    {
      slots = {},
      slotProps = {},
      format = 'LL',
      required = false,
      useFormatForTodaysDate = false,
      ...DatePickerProps
    }: AxDatePickerProps<TDate, TEnableAccessibleFieldDOMStructure>,
    ref: Ref<HTMLDivElement>
  ) => {

  return (
    <LocalizationProvider dateAdapter={ AdapterMoment } adapterLocale='userLocale'>
      <StyledAxDatePicker
        format={ format }
        { ...DatePickerProps }
        slots={{
          ...slots,
          textField: useFormatForTodaysDate ? AxTextField : CustomDatePickerTextField,
          inputAdornment: AxInputAdornment,
          openPickerButton: AxIconButton,
          openPickerIcon: AxIcon
        }}
        slotProps={{
          ...slotProps,
          textField: {
            required: required,
            dateFormat: format,
            placeholder: I18n.t('mui.datepicker.selectDate'),
            ...(slotProps?.textField ?? {})
          } as CustomDatePickerTextFieldProps & TextFieldProps,
          openPickerButton: {
            size: 'medium',
            ...(slotProps?.openPickerButton ?? {})
          },
          openPickerIcon: {
            className: 'icon-frequenttraining',
            ...(slotProps?.openPickerIcon ?? {})
          },
          layout: {
            sx: {
              ...useDateTimeStyles(),
              ...slotProps?.layout?.sx
            }
          }
        }}
        ref={ ref }
      />
    </LocalizationProvider>
  );
});

export default AxDatePicker;
