import I18nString from '@common/components/I18nString/I18nString';
import { joinClassNames } from '@common/libs/helpers/app/HTMLHelpers';
import { getValue } from '@common/libs/helpers/types/ObjectHelpers';
import { capitalizeFirstCharacter } from '@common/libs/helpers/types/StringHelpers';
import { fieldRequiredAsteriskStyles } from '@common/modules/react/themes/components/custom/atoms/FieldRequiredAsterisk';
import { useAxFormHelperTextStyles } from '@common/modules/react/themes/components/inputs/AxForm';
import { DefaultAxSelectProps } from '@common/modules/react/themes/components/inputs/AxSelect';
import AxInputAdornment from '@common/modules/react/themes/components/inputs/AxTextField/AxInputAdornment';
import { AxInputLabel } from '@common/modules/react/themes/components/inputs/AxTextField/AxInputLabel';
import {
  TextField as MuiTextField,
  styled,
  useTheme,
  type OutlinedTextFieldProps as MuiTextFieldProps,
  type Theme
} from '@mui/material';
import { type SystemProps } from '@mui/system';
import {
  defaultsDeep,
  isString
} from 'lodash';
import {
  Fragment,
  cloneElement,
  forwardRef,
  useDeferredValue,
  useLayoutEffect,
  useState,
  type Ref
} from 'react';

// These are repeated here from globals.d.ts otherwise storybook doesn't generate the controls tabled properly.
type DistributiveOmit<T, K extends keyof T> = T extends unknown ? Omit<T, K> : never;
type Override<T extends IndexableObject<object>, R extends IndexableObject<object>> = DistributiveOmit<T, Extract<keyof R, keyof T>> & R;

export type AxBaseTextFieldProps = Override<DistributiveOmit<MuiTextFieldProps, 'color' | 'margin' | 'variant'>, {
  /**
   * A class name used for quality assurance testing that can be applied automatically to the appropriate sub component that needs it.
   */
  qaClassName: `qa-${ string }`

  size?: 'medium' | 'large'

  /**
   * Convenience prop to set the width of the input field.
   */
  InputWidth?: SystemProps<Theme>['width']
}>;

type AxTextFieldWithMaxLengthEndAdornmentProps = Override<AxBaseTextFieldProps, {
  value?: string
  defaultValue?: string

  /**
   * Limits the max length of the input field and displays the remaining characters.
   *
   * If you want to set a max length WITHOUT showing the remaining characters, pass in
   * ```
   * inputProps: { maxLength: number }
   * ```
   * instead.
   */
  withMaxLengthEndAdornment: number
}>;

export type AxTextFieldProps = AxBaseTextFieldProps | AxTextFieldWithMaxLengthEndAdornmentProps;

const StyledAxTextField = styled(MuiTextField, {
  name: 'AxTextField',
  shouldForwardProp: (prop: string) => {
    return !['withMaxLengthEndAdornment', 'qaClassName', 'InputWidth'].includes(prop);
  }
})(({ theme }) => {
  const defaultStyles = {
    '&.MuiTextField-sizeLarge': {
      '.MuiInputBase-input': {
        paddingBottom: theme.uiKit.textFieldPaddingYL,
        paddingTop: theme.uiKit.textFieldPaddingYL
      },
      '.MuiInputBase-root .MuiButtonBase-root': {
        padding: theme.uiKit.textFieldPaddingYM,
        fontSize: theme.uiKit.fontSizeXL
      }
    },
    '.MuiFormLabel-root': {
      color: theme.uiKit.colorGrey70,
      fontSize: theme.uiKit.fontSizeXS,
      lineHeight: 'inherit',
      margin: 0,
      marginBottom: theme.uiKit.spacingXS,
      position: 'relative',
      transform: 'unset',
      '&.Mui-focused': {
        color: theme.uiKit.colorGrey70,
        fontSize: theme.uiKit.fontSizeXS
      },
      '&.Mui-error': {
        color: theme.uiKit.colorRed60
      },
      '.MuiFormLabel-asterisk': fieldRequiredAsteriskStyles(theme)
    },
    '.MuiInputBase-root': {
      backgroundColor: theme.uiKit.textFieldColorBackground,
      borderRadius: theme.uiKit.textFieldRadius,
      '&.Mui-disabled, \
       &.Mui-disabled:hover': {
        backgroundColor: theme.uiKit.textFieldDisabledColorBackground,
        borderColor: theme.uiKit.textFieldDisabledColorBorder,
        cursor: 'not-allowed',
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: theme.uiKit.textFieldDisabledColorBorder
        }
      },
      '&.Mui-focused, \
       &.Mui-focused.Mui-error': {
        ' .MuiOutlinedInput-notchedOutline, \
        &:hover .MuiOutlinedInput-notchedOutline': {
          ...theme.mixins.componentFocusInputStyles(),
          borderWidth: `.1rem`
        },
        ' .focus-visible': {
          // Remove default ax-form input focus state
          borderColor: 'transparent',
          boxShadow: 'none'
        }
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline, \
       &.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
        ...theme.mixins.componentErrorInputStyles()
      },
      '&.MuiInputBase-adornedStart': {
        paddingLeft: theme.uiKit.textFieldPaddingYL,
        ...theme.mixins.useGapPolyfillStyles('row', theme.uiKit.textFieldPaddingYM),
        '.MuiInputBase-input': {
          paddingLeft: 0
        }
      },
      '&.MuiInputBase-adornedEnd': {
        paddingRight: theme.uiKit.textFieldPaddingYL,
        '.MuiInputBase-input': {
          paddingRight: 0
        }
      },
      '&.MuiInputBase-adornedEnd:not(.MuiInputBase-multiline)': {
        ...theme.mixins.useGapPolyfillStyles('row', theme.uiKit.textFieldPaddingYM)
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.uiKit.colorGrey50
      },
      '.MuiOutlinedInput-notchedOutline': {
        top: '-0.6rem',
        border: `${ theme.uiKit.textFieldWidthBorder } solid ${ theme.uiKit.textFieldColorBorder }`,
        transition: `border-color ${ theme.uiKit.transitionSpeed } ${ theme.uiKit.transitionEase }`
      },
      '.MuiButtonBase-root': {
        padding: 0,
        zIndex: 1,
        borderColor: 'transparent',
        borderRadius: theme.uiKit.borderRadiusS,
        background: 'transparent',
        '&:hover': {
          borderColor: 'transparent',
          backgroundColor: theme.uiKit.colorGrey20
        },
        '&.Mui-focusVisible': {
          ...theme.mixins.componentFocusStyles()
        }
      }
    },
    '.MuiInputBase-input': {
      // Fake border for proper input height (actual visible border is on input base instead)
      border: `${ theme.uiKit.textFieldWidthBorder } solid transparent`,
      boxSizing: 'border-box',
      color: theme.uiKit.textFieldColorFont,
      height: 'auto',
      lineHeight: theme.uiKit.textFieldLineHeight,
      padding: `${ theme.uiKit.textFieldPaddingYM } ${ theme.uiKit.textFieldPaddingYL }`,
      '&.Mui-disabled': {
        cursor: 'not-allowed',
        border: 'transparent'
      },
      '&::placeholder, \
       &::-webkit-input-placeholder, \
       &:-moz-placeholder, \
       &:-ms-input-placeholder': {
        color: theme.uiKit.textFieldColorPlaceholder,
        opacity: 1
      }
    },
    '.MuiIcon-root': {
      fontSize: theme.uiKit.fontSizeXL
    }
  };

  return defaultStyles;
});

export const AxTextField = forwardRef(({
  children,
  className = '',
  select = false,
  size = 'medium',
  disabled = false,
  multiline = false,
  FormHelperTextProps = {},
  value,
  ...otherProps
}: AxTextFieldProps, ref: Ref<HTMLDivElement>) => {
  const textFieldProps = {
    className,
    select,
    size,
    disabled,
    multiline,
    FormHelperTextProps,
    value: value === null ? '' : value,
    ...otherProps
  };

  if (hasMaxLengthProps(textFieldProps)) {
    return <AxMaxLengthTextField { ...textFieldProps } ref={ ref }>{ children }</AxMaxLengthTextField>;
  }

  return <AxBaseTextField { ...textFieldProps } ref={ ref }>{ children }</AxBaseTextField>;
});

export default AxTextField;

/**
 * AxBaseTextField is the UIKit standard text field component.
 */

const AxBaseTextField = forwardRef(({
  children,
  ...otherProps
}: AxBaseTextFieldProps, ref: Ref<HTMLDivElement>) => {
  const textFieldProps = useAxModifiedProps(otherProps);

  if (textFieldProps.label && textFieldProps.label.type === AxInputLabel) {
    textFieldProps.label = cloneElement(textFieldProps.label, {
      as: Fragment
    });
  }

  return (
    <StyledAxTextField
      { ...textFieldProps }
      ref={ ref }
    >
      { children }
    </StyledAxTextField>
  );
});

function useAxModifiedProps(props: AxBaseTextFieldProps) {
  const {
    size,
    select,
    className,
    qaClassName,
    InputWidth
  } = props;

  const theme = useTheme();

  const inputWidth = getValue(InputWidth, null, theme);

  const widthFallback = (inputWidth == null ? {
    minWidth: theme.uiKit.textFieldWidthMin
  } : {
    width: inputWidth
  });

  const updatedProps = defaultsDeep(props, {
    InputLabelProps: {
      shrink: true
    },
    InputProps: {
      notched: false,
      sx: {
        padding: 0,
        ...widthFallback
      }
    },
    SelectProps: DefaultAxSelectProps,
    FormHelperTextProps: {
      sx: {
        ...useAxFormHelperTextStyles(),
        marginTop: theme.uiKit.spacingS
      }
    }
  });

  updatedProps.variant = 'outlined';

  if (isString(size)) {
    updatedProps.className = joinClassNames(className, `MuiTextField-size${ capitalizeFirstCharacter(size) }`);
  }

  if (select) {
    updatedProps.SelectProps = {
      ...updatedProps.SelectProps,
      sx: widthFallback,
      className: joinClassNames(updatedProps?.SelectProps?.className, qaClassName)
    };
  } else {
    updatedProps.inputProps = {
      ...updatedProps.inputProps,
      className: joinClassNames(updatedProps?.inputProps?.className, qaClassName)
    };
  }

  return updatedProps;
}

/**
 * AxMaxLengthTextField decorates the AxBaseTextField with MaxLength end adornment.
 */

type AxMaxLengthTextFieldProps = AxBaseTextFieldProps & {
  withMaxLengthEndAdornment: number
};

const AxMaxLengthTextField = forwardRef(({
  children,
  withMaxLengthEndAdornment,
  ...otherProps
}: AxMaxLengthTextFieldProps, ref: Ref<HTMLDivElement>) => {
  let textFieldProps = useAxModifiedProps(otherProps);
  textFieldProps = useMaxLengthEndAdornment(textFieldProps, withMaxLengthEndAdornment);

  return (
    <StyledAxTextField { ...textFieldProps } ref={ ref }>
      { children }
    </StyledAxTextField>
  );
});

function useMaxLengthEndAdornment(props: AxBaseTextFieldProps, withMaxLengthEndAdornment: number) {
  const {
    disabled,
    defaultValue,
    InputProps = {},
    multiline,
    onChange,
    value,
    inputProps = {}
  } = props;

  const theme = useTheme();

  const initialValue = getInitialValue(value ?? defaultValue);

  const [internalValue, setValue] = useState(initialValue);
  const deferredValue = useDeferredValue(internalValue);

  useLayoutEffect(() => {
    if (initialValue != null) {
      setValue(initialValue);
    }
  }, [initialValue]);

  if (InputProps?.endAdornment != null) {
    throw new Error('InputProps.endAdornment is not supported when using withMaxLengthEndAdornment');
  }

  const adjustedProps = props;

  adjustedProps.inputProps = {
    maxLength: withMaxLengthEndAdornment,
    ...inputProps
  };

  adjustedProps.InputProps = {
    ...InputProps,
    sx: {
      ...InputProps?.sx,
      ...(multiline && {
        paddingBottom: theme.uiKit.spacingL
      })
    }
  };

  if (!disabled) {
    adjustedProps.onChange = (event) => {
      setValue(event.target.value);
      onChange?.(event);
    };

    adjustedProps.InputProps.endAdornment = (
      <AxInputAdornment
        position='end'
        sx={{
          pointerEvents: 'none',
          ...(multiline && {
            position: 'absolute',
            bottom: 0,
            right: 0,
            marginRight: theme.uiKit.spacingS,
            marginBottom: theme.uiKit.spacingXS,
            alignItems: 'end'
          })
        }}
      >
        <p className='off-screen js-off-screen-character-count' aria-live='polite'></p>
        <I18nString
          i18nKey='accessibility.inputRemainingCharacters'
          as='p'
          className='off-screen'
          aria-live='polite'
          values={{
            value: withMaxLengthEndAdornment - deferredValue.length
          }}
        />
        { `${ deferredValue.length }/${ withMaxLengthEndAdornment }` }
      </AxInputAdornment>
    );
  }

  return adjustedProps;
}

/**
 * Checks if the given props object is of type `AxMaxLengthTextFieldProps`.
 *
 * @param props - The props object to check.
 * @returns A boolean indicating whether the props object has `withMaxLengthEndAdornment` property and a length property.
 */
function hasMaxLengthProps(props: AxTextFieldProps): props is AxMaxLengthTextFieldProps {
  return 'withMaxLengthEndAdornment' in props;
}

/**
 * Checks if the given value has a `length` property.
 *
 * @param value - The value to check.
 * @returns `true` if the value has a `length` property, `false` otherwise.
 */
function hasLengthProperty(value: unknown): value is { length: number } {
  return value !== null && (typeof value === 'object' && 'length' in value || typeof value === 'string');
}

/**
 * Returns the initial value for the input field.
 * If the value has a length property, it is returned as is.
 * Otherwise, an empty string is returned.
 *
 * @param value - The value to check.
 * @returns The initial value for the input field.
 */
function getInitialValue(value: unknown) {
  if (hasLengthProperty(value)) {
    return value;
  }

  return '';
}
