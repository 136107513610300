const I18n = require('@common/libs/I18n');
const Enum = require('@common/data/enums/Enum');

module.exports = Enum({
  // client only eceptions codes
  NO_ERROR_CODE: -1,
  PARSE_ERROR_CODE: -2,
  GCP_REFERENCE_ERROR: -3,

  // Server exception codes
  CONTRACT_ERROR_INVALID_PARAMETER_MISSING: 2001,
  CONTRACT_ERROR_INVALID_PARAMETER_WRONG_TYPE: 2002,
  CONTRACT_ERROR_INVALID_PARAMETER_WRONG_VALUE: 2003,
  CONTRACT_ERROR_INVALID_PARAMETER_MISSING_FROM_ENTITY: 2004,
  CONTRACT_ERROR_INVALID_PARAMETER_EXTRA: 2005,
  CONTRACT_ERROR_INVALID_TENANT: 2006,
  CONTRACT_ERROR_OPERATION_NOT_ALLOWED: 2007,
  CONTRACT_ERROR_VALUE_TOO_LONG: 2008,
  CONTRACT_ERROR_INVALID_JSON: 2009,
  CONTRACT_ERROR_INVALID_TOKEN_COUNT: 2010,
  CONTRACT_ERROR_UNCOMPLEX_PASSWORD: 2011,
  CONTRACT_ERROR_FEATURE_UNAVAILABLE: 2012,
  CONTRACT_ERROR_INVALID_REPORT_CONFIGURATION: 2013,
  CONTRACT_ERROR_INVALID_PARAMETER_EMPTY: 2014,
  CONTRACT_ERROR_GENERIC_INVALID_JSON: 2015,
  CONTRACT_ERROR_SELF_REGISTRATION_INVALID_ID_HIDDEN_COMBINATION: 2016,
  CONTRACT_ERROR_INVALID_TIME_PERIOD_TYPE: 2017,

  CLIENT_ERROR_NO_SUCH_ENTITY: 3001,
  CLIENT_ERROR_DUPLICATE_ENTITY: 3002,
  CLIENT_ERROR_AUTHENTICATION_FAILED: 3003,
  CLIENT_ERROR_INVALID_TOKEN: 3004,
  CLIENT_ERROR_FILE_ALREADY_IN_USE: 3005,
  CLIENT_ERROR_STALE_ENTITY: 3006,
  CLIENT_ERROR_DELETE_FAILED_HAS_CHILDREN: 3007,
  CLIENT_ERROR_NO_SUCH_VALID_ENTITY: 3008,
  CLIENT_ERROR_NO_SUCH_INVALID_ENTITY: 3009,
  CLIENT_ERROR_INVALID_PROPERTY_VALUE: 3010,
  CLIENT_ERROR_INSUFFICIENT_QUANTITY: 3012,
  CLIENT_ERROR_TOO_MANY: 3013,
  CLIENT_ERROR_INVALID_VALUE: 3014,
  CLIENT_ERROR_CANT_PERFORM_OPERATION: 3015,
  CLIENT_ERROR_INVALID_INPUT: 3016,
  CLIENT_ERROR_NOT_AUTHORIZED: 3017,
  CLIENT_ERROR_ACTIVITY_CORRUPT: 3018,
  CLIENT_ERROR_INVALID_ZIPFILE: 3019,
  CLIENT_ERROR_INVALID_SCORMFILE: 3020,
  CLIENT_ERROR_FILE_TOO_LARGE: 3021,
  CLIENT_ERROR_INTEGRATION_FAILURE: 3023,
  CLIENT_ERROR_EXPIRED_PASSWORD: 3024,
  CLIENT_ERROR_INVALID_PASSWORD: 3025,
  CLIENT_ERROR_INVALID_AUTH_SCHEME: 3026,
  CLIENT_ERROR_DELETE_FAILED_IS_REFERENCED: 3027,
  CLIENT_ERROR_NO_RECOVERY_QUESTION: 3028,
  CLIENT_ERROR_DUPLICATE_EMPLOYEE_ID: 3029,
  CLIENT_ERROR_SUSPENDED_ACCOUNT: 3030,
  CLIENT_ERROR_AUCTION_CLOSED: 3031,
  CLIENT_ERROR_GAME_CONFIGURATION: 3032,
  CLIENT_ERROR_VALUE_OUT_OF_BOUNDS: 3033,
  CLIENT_ERROR_DUPLICATE_MODULES: 3035,
  CLIENT_ERROR_CANNOT_WIN_PRIZE: 3036,
  CLIENT_ERROR_CANNOT_WITHDRAW_POINTS: 3037,
  CLIENT_ERROR_UNDELETE_FAILED_INVALID_REFERENCE: 3038,
  CLIENT_ERROR_LOCKED_ACCOUNT: 3039,
  CLIENT_ERROR_INVALID_TINCANFILE: 3040,
  CLIENT_ERROR_TOO_MANY_TINCAN_ACTIVITIES: 3041,
  CLIENT_ERROR_NO_TINCAN_LAUNCH_ACTIVITY: 3042,
  CLIENT_ERROR_SESSION_STATE: 3043,
  CLIENT_ERROR_DELETE_FAILED_REFERENCED_BY_REWARD_PROGRAM: 3044,
  CLIENT_ERROR_USER_CANNOT_SUBSCRIBE_TO_REPORT: 3045,
  CLIENT_ERROR_INVALID_TEAMGAME: 3046,
  CLIENT_ERROR_OAUTH_REFRESH_TOKEN_FAILED: 3047,
  CLIENT_ERROR_INVALID_RELATIONSHIPS: 3048,
  CLIENT_ERROR_EMPTY_ASSESSMENT: 3050,
  CLIENT_ERROR_ASSESSMENT_NOT_APPLICABLE: 3051,
  CLIENT_ERROR_EXTERNAL_CODE_EXISTS: 3054,
  CLIENT_ERROR_INVALID_REGISTRATION_LINK: 3055,
  CLIENT_ERROR_CHALLENGE_ALREADY_COMPLETE: 3056,
  CLIENT_ERROR_VIRUS_DETECTED: 3057,
  CLIENT_ERROR_MAX_SPINS_PER_DAY_REACHED: 3058,
  CLIENT_ERROR_OBJECT_ALREADY_LOCKED: 3059,
  CLIENT_ERROR_TOO_MANY_REQUESTS: 3060,
  CLIENT_ERROR_OPERATION_REQUIRES_LOCK: 3061,
  DISABLED_OBSERVATION_EDIT: 3062,
  CLIENT_ERROR_BUSINESS_UNIT_LOCATION_ERROR: 3063,
  CLIENT_ERROR_UNSAFE_CONTENT: 3064,
  CLIENT_ERROR_PROGRAM_ENDED: 3065,
  EVENT_MGMT_USER_NOT_QUALIFIED: 3066,
  EVENT_MGMT_WOULD_EXCEED_CAPACITY: 3067,
  EVENT_MGMT_SELF_ENROLLMENT_NOT_ALLOWED: 3069,
  EVENT_MGMT_ENROLLMENT_BY_LEADER_NOT_ALLOWED: 3070,
  EVENT_MGMT_USER_TO_ENROLL_NOT_VISIBLE_TO_LEADER: 3071,
  EVENT_MGMT_USER_CANNOT_ENROLL_OTHER_USER: 3072,
  EVENT_MGMT_SESSION_OVERLAP: 3073,
  EVENT_MGMT_WAITING_LIST_NOT_SUPPORTED: 3074,
  INPUT_VALIDATION_FAILURE: 3075,
  EVENT_MGMT_TOO_MANY_USERS_FOR_BULK_ENROLL: 3076,
  EVENT_MGMT_TOO_MANY_ENROLLMENTS_FOR_BULK_REMOVE: 3077,
  EVENT_MGMT_TOO_MANY_ENROLLMENTS_FOR_BULK_UPDATE: 3078,
  COMPANY_ATTRIBUTE_BULK_DELETION_ALREADY_IN_PROGRESS: 3079,
  BRANDING_CANNOT_DELETE_DEFAULT: 3080,
  CLIENT_ERROR_REVIEW_ALREADY_CLOSED: 3081,
  CLIENT_ERROR_NOT_FOUND: 3082,
  CLIENT_ERROR_UNSUPPORTED_FILE_TYPE: 3083,
  CLIENT_ERROR_CANNOT_ADD_MUST_INCLUDE_AOIS: 3084,
  CLIENT_ERROR_BUSINESS_UNIT_USAGE: 3085,
  CLIENT_ERROR_LOCATION_MULTIPLE_BUSINESS_UNITS: 3086,
  CLIENT_ERROR_BUSINESS_UNIT_TRAINING_GROUP_NEEDS_ONE_LOCATION: 3087,
  CLIENT_ERROR_BUSINESS_UNIT_CONSISTENCY_PROBLEMS: 3088,
  CLIENT_ERROR_BUSINESS_UNIT_CODE_EXISTS: 3089,
  CLIENT_ERROR_LRS_PROBLEM: 3090,
  CLIENT_ERROR_BOUNTY_ALREADY_CLAIMED: 3091,
  CLIENT_ERROR_BOUNTY_INVALID_STATE: 3092,
  CLIENT_ERROR_BOUNTY_TOO_MANY_CLAIMED: 3093,
  CLIENT_ERROR_BOUNTY_CLOSED: 3094,
  CLIENT_ERROR_NO_BROADCAST_MESSAGE_PERMISSION: 3095,
  CLIENT_ERROR_CANNOT_REMOVE_LEADERBOARD_POINTS: 3096,
  CLIENT_ERROR_TARGET_AUDIENCE_TOO_LARGE: 3097,
  CLIENT_ERROR_USER_AGREEMENT_DISABLED: 3098,
  CLIENT_ERROR_OPERATION_ON_TIMEOUT: 3122,
  CLIENT_ERROR_RESOURCE_IS_SUSPENDED: 3123,
  EVENT_MGMT_INVALID_INSTRUCTOR: 3099,
  ACCOUNT_RECOVERY_INVALID_TOKEN: 3101,
  ACCOUNT_RECOVERY_NO_SUCH_USER: 3102,
  ACCOUNT_RECOVERY_SUSPENDED_USER: 3103,
  ACCOUNT_RECOVERY_USER_LOCKED_OUT: 3104,
  FAILED_RECAPTCHA_VALIDATION: 3105,
  ADHOC_REPORT_FILTER_MISMATCH: 3109,
  CLIENT_ERROR_SUPERUSER_PASSWORD_EXPIRED: 3112,
  TOO_MANY_SFTP_KEYS: 3115,
  EMAIL_ADDRESS_REQUIRED: 3116,
  CLIENT_ERROR_INVALID_NUMBER_OF_OBSERVATIONS_FOR_EVALUATION_BEHAVIOUR: 3130,
  CLIENT_ERROR_CANNOT_MODIFY_OBSERVATIONS_AFTER_REOPENING: 3133,
  SECURITY_QUESTION_ANSWER_TOO_SHORT: 3134,
  SECURITY_QUESTION_DUPLICATE_ANSWER: 3135,
  CLIENT_ERROR_EVALUATION_NO_ACTIVE_CONTENT: 3136,
  CLIENT_ERROR_LANGUAGE_NOT_SUPPORTED: 3138,
  CLIENT_ERROR_PROFANE_LANGUAGE: 3139,
  RECYCLED_PASSWORD: 3140,
  CLIENT_ERROR_ON_THE_CLOCK_REQUIRED: 3141,
  CLIENT_ERROR_TENANT_PROPERTY_DISABLED: 3142,
  CLIENT_ERROR_ACTIVITY_NOT_UNDERWAY: 3143,
  CLIENT_ERROR_MFA_AUTHENTICATION_REQUIRED: 3152,
  CLIENT_ERROR_MFA_INVALID: 3153,
  CLIENT_ERROR_CONTENT_EXPIRY: 3155,
  UNABLE_TO_UPDATE_TOPIC_QUESTION_SEQUENCE: 3158,
  CLIENT_ERROR_INVALID_QUESTION_SEQUENCE_REORDER: 3159,
  UNABLE_TO_ENABLE_TOPIC_QUESTION_SEQUENCE_FOR_TOPIC_WITH_MULTIPLE_VARIANTS: 3160,
  CLIENT_ERROR_REASSIGN_ALREADY_REASSIGNED: 3161,
  CLIENT_ERROR_NULL_DUE_DATE_FOR_MILESTONE_DUE_DATE_TYPE: 3162,
  CLIENT_ERROR_EXTERNAL_CODE_MISSING: 3163,
  CLIENT_ERROR_PIN_DEFINITION_GROUP_NOT_FOUND: 3164,
  CLIENT_ERROR_PIN_DEFINITION_NOT_ACCESSIBLE_SENDER: 3165,
  CLIENT_ERROR_PIN_DEFINITION_NOT_ACCESSIBLE_RECIPIENT: 3166,
  CLIENT_ERROR_PIN_DEFINITION_MESSAGE_NOT_ALLOWED: 3167,
  LOCALIZABLE_IMAGE_NO_IMAGES: 3168,
  CLIENT_ERROR_COMPLETE_ALREADY_COMPLETED_INSIGHT: 3169,
  CLIENT_ERROR_SNOOZE_ALREADY_COMPLETED_INSIGHT: 3170,
  UNABLE_TO_CREATE_MULTIPLE_ADHOC_INSPECTIONS_FOR_SAME_TEAM_PER_DAY: 3171,
  CLIENT_ERROR_EVENT_NAME_TRANSLATION_DUPLICATE: 3172,
  CLIENT_ERROR_LOOKER_ACQUIRE_EMBED_SESSION: 3180,
  CLIENT_ERROR_LOOKER_GENERATE_EMBED_TOKENS: 3181,
  CLIENT_ERROR_LOOKER_UPDATE_AUDIENCE_FILTER: 3182,
  CLIENT_ERROR_URGENT_TASK_DUE_DATE_VALIDATION: 3185,

  CLIENT_ERROR_FAKE_ERROR_FOR_TESTING_USE_ONLY: 3999,

  QUESTION_GENERATOR_CANNOT_GENERATE: 4103,
  SIMPLE_FILE_UPLOAD_RETRY: 4104,
  SERVER_ERROR_FAILED_LOOKER_EMBED_URL_GENERATION: 4106,
  LOOKER_USER_FORBIDDEN: 4107,

  SERVER_ERROR_STALE_RESOURCE: 9001,
  SERVER_ERROR_INTEGRATION_FAILURE: 9002,
  SERVER_ERROR_MOBILE_PUSH_FAILURE: 9003,
  SERVER_ERROR_REDIRECT: 9004,
  SERVER_ERROR_SSO_USER_NOT_FOUND: 9005,
  SERVER_ERROR_INVALID_SSO_RESPONSE: 9006,
  SERVER_ERROR_VIRUS_SCANNER_NOT_AVAILABLE: 9007,
  SERVER_ERROR_IO_FAILED_TO_GET_FILE_SIZE: 9008,
  SERVER_ERROR_IO_FAILED_TO_GET_READ_STREAM: 9009,
  SERVER_ERROR_IO_FAILED_TO_READ_FILE: 9010,
  SERVER_ERROR_IO_FAILED_TO_WRITE_FILE: 9011,
  SERVER_ERROR_IO_FAILED_TO_MOVE_FILE: 9012,
  SERVER_ERROR_IO_FAILED_TO_COPY_FILE: 9013,
  SERVER_ERROR_IO_FAILED_TO_DELETE_FILE: 9014,
  SERVER_ERROR_IO_FAILED_TO_UNZIP_FILE: 9015,
  SERVER_ERROR_IO_FAILED_TO_CREATE_DIRECTORIES: 9016,
  SERVER_ERROR_IO_FAILED_TO_PROCESS_IMAGE: 9017,
  SERVER_ERROR_IO_FAILED_TO_LIST_FILES: 9018,
  SERVER_ERROR_IO_FAILED_TO_GET_FILE_CONTENT_TYPE: 9019,
  SERVER_ERROR_IO_FAILED_TO_WRITE_DIRECTORY: 9020,
  SERVER_ERROR_IP_FORBIDDEN: 9021,
  SERVER_ERROR_TIMEOUT: 9022,
  SERVER_ERROR_CLOUDSEARCH_SERVICE_PROBLEM: 9023,
  SERVER_ERROR_IO_DECRYPTION_PROBLEM: 9024,
  SERVER_ERROR_IO_GPG_DECRYPTION_NOT_CONFIGURED: 9025,
  SERVER_ERROR_IO_ODATA_METADATA_RETRIEVAL_FAIL: 9026,
  SERVER_ERROR_IO_ODATA_GENERIC_ERROR: 9027,
  SERVER_ERROR_SSO_GENERIC_ERROR: 9028,
  SERVER_ERROR_NLP_SERVICE_EXCEPTION: 9035,
  SERVER_ERROR_LLM_MESSAGE_QUEUE_SERVICE_EXCEPTION: 9036,
  SERVER_ERROR_LLM_SERVICE_EXCEPTION: 9050,
  SERVER_ERROR_LLM_RATE_LIMIT: 9051,
  SERVER_ERROR_LLM_BLOCKED: 9052,
  SERVER_ERROR_LLM_NO_DOCUMENTS_OR_ACCESS: 9053,
  SERVER_ERROR_CONVERSATIONAL_SEARCH_RATE_LIMIT_WEEKLY: 9054,
  SERVER_ERROR_CONVERSATIONAL_SEARCH_RATE_LIMIT_DAILY: 9055,

  // Client only exceptions
  AUTHENTICATION_ERROR_AT_WORK_VIOLATION: 11000
}, {
  onTheClockRequiredErrorFlashOptions() {
    return {
      string: I18n.t(`errors.${ this.CLIENT_ERROR_ON_THE_CLOCK_REQUIRED }`),
      timeout: 10000 // 10s
    };
  }
});
