const I18n = require('@common/libs/I18n');
const StackedCollectionView = require('@common/components/view/stacked_collection_view/StackedCollectionView');

const ViewConfigBuilder = require('@training/apps/training/assessmentProgress/ViewConfigBuilder');
const ProgressSectionBuilder = require('@training/apps/training/assessmentProgress/ProgressSectionBuilder');
const AssessmentModuleProgressConfig = require('@training/apps/training/assessmentProgress/AssessmentModuleProgressConfig');
const AssessmentQuizProgressConfig = require('@training/apps/training/assessmentProgress/AssessmentQuizProgressConfig');
const EndMarkerBuilder = require('@training/apps/training/assessmentProgress/EndMarkerBuilder');

const buildConfigsForAssessment = (assessment) => {
  const sectionsBuilder = new ProgressSectionBuilder();
  sectionsBuilder.addProgressConfig(new AssessmentModuleProgressConfig(assessment));
  sectionsBuilder.addProgressConfig(new AssessmentQuizProgressConfig(assessment));

  const configBuilder = new ViewConfigBuilder();
  configBuilder.addBuilder(sectionsBuilder);
  configBuilder.addBuilder(new EndMarkerBuilder(assessment));

  return configBuilder.build();
};

module.exports = (assessment) => {
  return () => {
    return {
      viewDefinition: {
        ViewClass: StackedCollectionView,
        className: 'assessment-progress-bar clearfix',
        attributes: {
          role: 'complementary',
          'aria-label': I18n.t('assessments.progress.label')
        },
        viewConfigs: buildConfigsForAssessment(assessment)
      },
      delegateEvents: {
        'view:inflate': (controller, view) => {
          controller.listenTo(assessment.activities, 'change:status', (activity) => {
            if (activity.isClosed()) {
              view.resetViewConfigs(buildConfigsForAssessment(assessment));
            }
          });
          controller.listenTo(assessment.assessmentResult, 'change:passed', () => {
            view.resetViewConfigs(buildConfigsForAssessment(assessment));
          });
        }
      }
    };
  };
};
