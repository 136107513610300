const {
  filter,
  reduce,
  extend
} = require('underscore');

const {
  createProgressMarkerViewConfig,
  createProgressBarViewConfig
} = require('@training/apps/training/assessmentProgress/ProgressViewConfigFactory');

const getProgressSectionWidthClass = (configCount, currentConfig) => {
  if (configCount === 1) {
    return 'full-width';
  } else if (currentConfig.containsActiveActivity()) {
    return 'large';
  }
  return 'small';
};

class ProgressSectionBuilder {
  _configs = [];

  addProgressConfig(progressConfig) {
    this._configs.push(progressConfig);
  }

  build() {
    const filteredConfigs = filter(this._configs, (progressConfig) => {
      return progressConfig.isValid();
    });
    const filteredConfigsCount = filteredConfigs.length;

    return reduce(filteredConfigs, (configs, progressConfig) => {
      const sectionWidthClass = getProgressSectionWidthClass(filteredConfigsCount, progressConfig);
      const progressSegment = createProgressBarViewConfig(extend(progressConfig.getProgressBarOptions(), { sectionWidthClass }));
      const marker = createProgressMarkerViewConfig(progressConfig.getMarkerOptions());

      return configs.concat(marker, progressSegment);
    }, []);
  }
}

module.exports = ProgressSectionBuilder;
