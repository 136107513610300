const {
  pick,
  isEmpty,
  defaults
} = require('underscore');
const { Model } = require('Backbone');

const Scrollable = require('@common/libs/behaviors/scrollable/Scrollable');

const Enum = require('@common/data/enums/Enum');

const DEFAULT_SCROLL_BUFFER = 1; // 1px to avoid potential rounding errors

const DataKeys = Scrollable.ScrollDataKeys.extend({
  AT_TOP_EDGE: 'scrollTopEdge',
  AT_BOTTOM_EDGE: 'scrollBottomEdge',
  AT_LEFT_EDGE: 'scrollLeftEdge',
  AT_RIGHT_EDGE: 'scrollRightEdge',
  IN_TOP_BUFFER: 'scrollTopBuffer',
  IN_BOTTOM_BUFFER: 'scrollBottomBuffer',
  IN_LEFT_BUFFER: 'scrollLeftBuffer',
  IN_RIGHT_BUFFER: 'scrollRightBuffer',
  IS_VERTICAL_OVERFLOW: 'scrollVerticalOverflow',
  IS_HORIZONTAL_OVERFLOW: 'scrollHorizontalOverflow',
  HORIZONTAL_SCROLL_DIRECTION: 'horizontalScrollDirection',
  VERTICAL_SCROLL_DIRECTION: 'verticalScrollDirection'
});

const OptionKeys = Enum({
  TOP_BUFFER: 'topBuffer',
  BOTTOM_BUFFER: 'bottomBuffer',
  LEFT_BUFFER: 'leftBuffer',
  RIGHT_BUFFER: 'rightBuffer'
});

const ScrollDirection = Enum({
  UP: 'up',
  DOWN: 'down',
  LEFT: 'left',
  RIGHT: 'right'
});

const ScrollDataKeys = [
  Scrollable.ScrollDataKeys.SCROLL_TOP,
  Scrollable.ScrollDataKeys.SCROLL_BOTTOM,
  Scrollable.ScrollDataKeys.SCROLL_LEFT,
  Scrollable.ScrollDataKeys.SCROLL_RIGHT
];

const ContainerDataKeys = [
  Scrollable.ScrollDataKeys.CONTAINER_HEIGHT,
  Scrollable.ScrollDataKeys.CONTAINER_WIDTH
];

const ContentDataKeys = [
  Scrollable.ScrollDataKeys.CONTENT_HEIGHT,
  Scrollable.ScrollDataKeys.CONTENT_WIDTH
];

const ScrollDirectionDataKeys = [
  DataKeys.HORIZONTAL_SCROLL_DIRECTION,
  DataKeys.VERTICAL_SCROLL_DIRECTION
];

const getChanges = (dataChanges = {}, ...keys) => {
  return pick(dataChanges, ...keys);
};

const hasDataChanges = (dataChanges = {}, ...keys) => {
  return !isEmpty(getChanges(dataChanges, ...keys));
};

const getEventStateChanges = (dataChanges = {}) => {
  return getChanges(dataChanges, ...DataKeys.values());
};

const hasContainerChanges = (dataChanges = {}) => {
  return hasDataChanges(dataChanges, ...ContainerDataKeys);
};

const getContainerChanges = (dataChanges = {}) => {
  return getChanges(dataChanges, ...ContainerDataKeys);
};

const hasContentChanges = (dataChanges = {}) => {
  return hasDataChanges(dataChanges, ...ContentDataKeys);
};

const getContentChanges = (dataChanges = {}) => {
  return getChanges(dataChanges, ...ContainerDataKeys);
};

const hasStructuralChanges = (dataChanges = {}) => {
  return hasDataChanges(dataChanges, ...ContainerDataKeys, ...ContentDataKeys);
};

const getStructuralChanges = (dataChanges = {}) => {
  return getChanges(dataChanges, ...ContainerDataKeys, ...ContentDataKeys);
};

const hasScrollPositionChanges = (dataChanges = {}) => {
  return hasDataChanges(dataChanges, ...ScrollDataKeys);
};

const getScrollPositionChanges = (dataChanges = {}) => {
  return getChanges(dataChanges, ...ScrollDataKeys);
};

const hasScrollDirectionChanges = (dataChanges = {}) => {
  return hasDataChanges(dataChanges, ...ScrollDirectionDataKeys);
};

const getScrollDirectionChanges = (dataChanges = {}) => {
  return getChanges(dataChanges, ...ScrollDirectionDataKeys);
};

class ScrollEventState extends Model {

  static Options = OptionKeys;

  static Keys = DataKeys;

  static ScrollDirection = ScrollDirection;

  static getChanges = getChanges;

  static hasDataChanges = hasDataChanges;

  static getEventStateChanges = getEventStateChanges;

  static hasContainerChanges = hasContainerChanges;

  static getContainerChanges = getContainerChanges;

  static hasContentChanges = hasContentChanges;

  static getContentChanges = getContentChanges;

  static hasStructuralChanges = hasStructuralChanges;

  static getStructuralChanges = getStructuralChanges;

  static hasScrollPositionChanges = hasScrollPositionChanges;

  static getScrollPositionChanges = getScrollPositionChanges;

  static hasScrollDirectionChanges = hasScrollDirectionChanges;

  static getScrollDirectionChanges = getScrollDirectionChanges;

  constructor(attrs, options = {}) {
    super(attrs, options);

    Object.assign(this, defaults(pick(options, OptionKeys.values()), {
      [OptionKeys.TOP_BUFFER]: DEFAULT_SCROLL_BUFFER,
      [OptionKeys.BOTTOM_BUFFER]: DEFAULT_SCROLL_BUFFER,
      [OptionKeys.LEFT_BUFFER]: DEFAULT_SCROLL_BUFFER,
      [OptionKeys.RIGHT_BUFFER]: DEFAULT_SCROLL_BUFFER
    }));
  }

  updateFromScrollData(scrollData) {
    const {
      scrollBottom,
      scrollTop,
      scrollLeft,
      scrollRight,
      containerHeight,
      contentHeight,
      containerWidth,
      contentWidth
    } = scrollData;

    const scrollDirection = this._calculateScrollDirection(scrollData);

    this.set(Object.assign({}, scrollData, {
      [DataKeys.IS_VERTICAL_OVERFLOW]: contentHeight > containerHeight,
      [DataKeys.IS_HORIZONTAL_OVERFLOW]: contentWidth > containerWidth,

      [DataKeys.AT_TOP_EDGE]: scrollTop <= DEFAULT_SCROLL_BUFFER,
      [DataKeys.AT_BOTTOM_EDGE]: scrollBottom <= DEFAULT_SCROLL_BUFFER,
      [DataKeys.AT_LEFT_EDGE]: scrollLeft <= DEFAULT_SCROLL_BUFFER,
      [DataKeys.AT_RIGHT_EDGE]: scrollRight <= DEFAULT_SCROLL_BUFFER,

      [DataKeys.IN_TOP_BUFFER]: scrollTop <= this[OptionKeys.TOP_BUFFER],
      [DataKeys.IN_BOTTOM_BUFFER]: scrollBottom <= this[OptionKeys.BOTTOM_BUFFER],
      [DataKeys.IN_LEFT_BUFFER]: scrollLeft <= this[OptionKeys.LEFT_BUFFER],
      [DataKeys.IN_RIGHT_BUFFER]: scrollRight <= this[OptionKeys.RIGHT_BUFFER],

      [DataKeys.HORIZONTAL_SCROLL_DIRECTION]: scrollDirection.horizontal,
      [DataKeys.VERTICAL_SCROLL_DIRECTION]: scrollDirection.vertical
    }));
  }

  getChanges(...keys) {
    const changes = this.changedAttributes() || {};
    return getChanges(changes, ...keys);
  }

  hasDataChanges(...keys) {
    const changes = this.changedAttributes() || {};
    return hasDataChanges(changes, ...keys);
  }

  getEventStateChanges() {
    return this.getChanges(...DataKeys.values());
  }

  hasContainerChanges() {
    return this.hasDataChanges(...ContainerDataKeys);
  }

  getContainerChanges() {
    return this.getChanges(...ContainerDataKeys);
  }

  hasContentChanges() {
    return this.hasDataChanges(...ContentDataKeys);
  }

  getContentChanges() {
    return this.getChanges(...ContainerDataKeys);
  }

  hasStructuralChanges() {
    return this.hasDataChanges(...ContainerDataKeys, ...ContentDataKeys);
  }

  getStructuralChanges() {
    return this.getChanges(...ContainerDataKeys, ...ContentDataKeys);
  }

  hasScrollPositionChanges() {
    return this.hasDataChanges(...ScrollDataKeys);
  }

  getScrollPositionChanges() {
    return this.getChanges(...ScrollDataKeys);
  }

  hasScrollDirectionChanges() {
    return this.hasDataChanges(...ScrollDirectionDataKeys);
  }

  getScrollDirectionChanges() {
    return this.getChanges(...ScrollDirectionDataKeys);
  }

  _calculateScrollDirection(scrollData) {
    const currentScrollTop = this.get(DataKeys.SCROLL_TOP);
    const currentScrollBottom = this.get(DataKeys.SCROLL_BOTTOM);
    const currentScrollLeft = this.get(DataKeys.SCROLL_LEFT);

    const newScrollTop = scrollData[DataKeys.SCROLL_TOP];
    const newScrollLeft = scrollData[DataKeys.SCROLL_LEFT];

    const scrollDirection = {
      vertical: null,
      horizontal: null
    };
    if (currentScrollTop <= 0) {
      scrollDirection.vertical = ScrollDirection.UP;
      return scrollDirection;
    }
    if (currentScrollBottom <= 0) {
      scrollDirection.vertical = ScrollDirection.DOWN;
      return scrollDirection;
    }

    if (newScrollTop > currentScrollTop) {
      scrollDirection.vertical = ScrollDirection.DOWN;
    } else if (newScrollTop < currentScrollTop) {
      scrollDirection.vertical = ScrollDirection.UP;
    }

    if (newScrollLeft > currentScrollLeft) {
      scrollDirection.horizontal = ScrollDirection.RIGHT;
    } else if (newScrollLeft < currentScrollLeft) {
      scrollDirection.horizontal = ScrollDirection.LEFT;
    }

    return scrollDirection;
  }
}

module.exports = ScrollEventState;
