import PdfContentShowView from '@common/components/mediaFilePreview/views/PdfContentShowView';
import FileFactory from '@common/data/models/media/FileFactory';
import ViewHelpers from '@common/libs/helpers/app/ViewHelpers';
import I18n from '@common/libs/I18n';
import Backbone from 'backbone';
import $os from 'detectOS';

interface downloadCertificationInterface {
  topicId: number,
  level: number,
  language: string,
  userType: string
}


export async function downloadCertification(options: downloadCertificationInterface) {
  const {
    topicId,
    level,
    language,
    userType
  } = options;

  window.app.layout.showSpinner(true);
  const response = await fetch(`/axonify/${ userType.toLowerCase() }/certificationItems/print/topic/${ topicId }/level/${ level }?languageCode=${ language }`, {
    method: 'GET',
    headers: {
      Accept: 'application/pdf',
      'X-Xsrf-Header': '1'
    }
  });

  window.app.layout.hideSpinner();

  if (!response.ok) {
    window.app.layout.flash.error(I18n.t('printCertification.downloadError'));
    Backbone.history.navigate('#', true);
  } else {
    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const fileFactory = new FileFactory();
    const documentMedia = fileFactory.createMediaFromJSON({
      originalFile: {
        originalFileName: 'Certification.pdf',
        path: url,
        status: 'done'
      },
      type: 'document'
    });

    const view = {
      viewDefinition: {
        ViewClass: PdfContentShowView,
        model: documentMedia,
        shouldShowPdfjsPrint: !$os.mobile,
        // forces download button to display, even if LEARNER_ZONE_ALLOW_PDF_DOWNLOAD is false. We
        // want this for Certificates, since they aren't uploaded documents
        forceShowPdfjsDownload: true
      },
      delegateEvents: {
        'view:before:attach': () => {
          window.app.layout.toggleFooter(false);
          window.app.layout.toggleFullPage(true);
        },
        'view:before:destroy': () => {
          window.app.layout.toggleFooter(true);
          window.app.layout.toggleFullPage(false);
        },
        'view:show': function (pdfView: typeof PdfContentShowView) {
          ViewHelpers.showBackButtonWithReset({view: pdfView});
        }
      }
    };

    window.app.layout.setView(view);
  }
}
