// Module
var code = `<!--QUESTION-->
<div class="multianswer">
  <div class="question-number-header"><%- t('question.questionNumberHeader', { questionOrdinal }) %></div>
  <div class="questionarea clearfix">
    <div role="heading" aria-level="2" class="questionask">
      <% if (variant.questionImg) { %>
    	<button class="questionimage imgwrap zoom-image-wrap" data-media-id="<%- variant.questionImg.preferred.id %>"></button>
      <% } %>
      <span class="question-text"><%= variant.questionText %></span>
    </div>
    <div class="clear"></div>
    <div class="bonuscharacter" <% if (bonuscharacter === 'NoCoach') { %>aria-hidden="true" role="presentation"<% } %>>
      <div role="img" aria-label="<%- t('coaches.thinking') %>" class="<%- bonuscharacter %> ponder"></div>
    </div>
    <div class="answerletter select-all-note" id="select-all-note">
      <div class="icon-CorrectAnswerStreak1" aria-hidden="true"></div>
      <span><%- t('question.multianswerinstruction') %></span>
    </div>
  </div>
  <div class="hinttext" aria-live="polite">
    <div role="heading" class="question-instruction"></div>
  </div>
  <!--ANSWERS-->
  <fieldset>
    <legend class="off-screen"><%- t('question.answers')%></legend>
    <div class="answerwrappersize">
      <div class="answerwrapper"></div>
    </div>
  </fieldset>
  <p class="off-screen" id="accessibility-note"><%- t('question.accessibility.multichoiceInstructions') %></p>
</div>
`;
// Exports
module.exports = code;