const logging = require('logging');
const { history } = require('Backbone');

const FlowController = require('@training/apps/training/controllers/FlowController');
const ProcessSequenceMessageCode = require('@training/apps/training/controllers/ProcessSequenceMessageCode');
const PageViewSource = require('@training/apps/training/enums/PageViewSource');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');
const Enum = require('@common/data/enums/Enum');

const NotificationTypes = Enum.fromStringArray([
  'POST',
  'ARTICLE',
  'ANNOUNCEMENT',
  'QUIZ',
  'POLL',
  'TASK'
], {
  isTaskNotification: (notificationType) => {
    return 'TASK' === notificationType
  },
  isDiscoverNotification: (notificationType) => {
    return ['POST', 'ARTICLE'].includes(notificationType)
  },
  isCommunicationNotification: (notificationType) => {
    return ['ANNOUNCMENT', 'QIUZ', 'POLL'].includes(notificationType)
  }
});

class MobileNotificationsController extends FlowController {

  static NotificationTypes = NotificationTypes
  
  parseDataFromHash(hash, errCb) {
    const queryParams = UrlHelpers.getQueryParams(hash);

    if (!queryParams.data) {
      errCb('Problem with Mobile Notification, no data param found', hash);
      return undefined;
    }

    let dataObj;

    try {
      dataObj = JSON.parse(decodeURIComponent(queryParams.data));
    } catch (e) {
      errCb('Problem with Mobile Notification, unable to parse data param', queryParams.data);
      return undefined;
    }

    if (!dataObj.navigate) {
      errCb('Problem with Mobile Notification, navigate property not present', dataObj);
      return undefined;
    }

    const type = dataObj.navigate.type;
    const id = dataObj.navigate.id;

    try {
      NotificationTypes.assertLegalValue(type);
    } catch (e) {
      errCb('Problem with Mobile Notification, unknown notification type', type);
      return undefined;
    }

    if (!id) {
      errCb('Problem with Mobile Notification, id not present', id);
      return undefined;
    }

    return {
      type,
      id
    };
  }

  tryGetNewUrlOrMobileNotification({
    hash = window.location.hash,
    errCb = logging.error
  } = {}) {
    if (!hash.includes('#mobileNotification')) {
      return undefined;
    }

    const parsedData = this.parseDataFromHash(hash, errCb);
    if (!parsedData) {
      return undefined;
    }

    const {
      type,
      id
    } = parsedData;

    if (!id) {
      return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
    }

    if (NotificationTypes.isDiscoverNotification(type)) {
      return `hub/search/article/${ id }?source=${ PageViewSource.MOBILE_NOTIFICATION }`;
    }

    // broadcast controller takes care of showing broadcast message instead of just returning a url
    if (NotificationTypes.isCommunicationNotification(type)) {
      this.handleBroadcastMessage(id);

      return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
    }

    return undefined;
  }

  handleBroadcastMessage(id) {
    this.parentProcessor.pauseCurrentAssessment().then(() => {
      return this.fetchAndProcessBroadcastMessage(id);
    })
  }

  handleTaskMessage(params = {}) {
    const {
      taskInstanceId,
      taskType
    } = params;
    const queryParamsMap = {};
    let path = `hub/taskList?source=${ PageViewSource.MOBILE_NOTIFICATION }`;
    if (taskInstanceId && taskType) {
      queryParamsMap.taskInstanceId = taskInstanceId;
      queryParamsMap.taskType = taskType;
    }
    path = UrlHelpers.appendQueryParams(path, queryParamsMap);

    history.navigate(path, {
      trigger: true,
      replace: true
    });
  }

  fetchAndProcessBroadcastMessage(id) {
    const broadcastController = this.parentProcessor.getBroadcastMessageController();
    return broadcastController.processSequenceFlow(id).catch(() => {
      /* nomnomnom */
    });
  }

  handleNativeBridgeMobileNotifications() {
    const {
      type,
      id
    } = this.parentProcessor.currentNotificationData;
    if (NotificationTypes.isTaskNotification(type)) {
      this.handleTaskMessage(this.parentProcessor.currentNotificationData);
      this.parentProcessor.currentNotificationData = {};
      return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
    } else if (!id) {
      return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
    }

    if (NotificationTypes.isCommunicationNotification(type)) {
      this.handleBroadcastMessage();

      return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
    } else if (NotificationTypes.isDiscoverNotification(type)) {
      history.navigate(`hub/search/article/${ id }?source=${ PageViewSource.MOBILE_NOTIFICATION }`, {
        trigger: true,
        replace: true
      });

      this.parentProcessor.currentNotificationData = {};
      return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
    }

    return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
  }

  processSequenceFlow() {
    if (this.parentProcessor.currentNotificationData && Object.keys(this.parentProcessor.currentNotificationData).length) {
      return this.handleNativeBridgeMobileNotifications();
    }

    const result = this.tryGetNewUrlOrMobileNotification();

    if (result) {
      // if URL is returned, navigate user otherwise show let controller handle showing notification
      if (typeof result === 'string') {
        history.navigate(result, {
          trigger: true,
          replace: true
        });
      }
      return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
    }

    return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
  }
}

module.exports = MobileNotificationsController;
