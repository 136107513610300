const logging = require('logging');
const _ = require('underscore');

const KeyCode = require('@common/data/enums/KeyCode');
const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');
const I18n = require('@common/libs/I18n');

const QuestionView = require('@training/apps/training/views/activities/pages/questions/QuestionView');

class MultipleChoiceQuestionPage extends QuestionView {
  events() {
    return {
      'click .answer': 'selectAnswer',
      'click .answerradio': 'clickAnswer',
      'keydown .answerradio': 'keydownAnswer'
    };
  }

  ui() {
    return {
      accessibilityNote: '#accessibility-note'
    };
  }

  constructor(...args) {
    super(...args);
    this.template = _.tpl(require('@training/apps/training/templates/activities/pages/questions/MultipleChoiceQuestionPage.html'));
    this.rowTemplate = _.tpl(require('@training/apps/training/templates/activities/pages/questions/_multichoice_question_row.html'));
    this.explainTemplate = _.tpl(require('@training/apps/training/templates/activities/pages/questions/_multichoice_reason_row.html'));
  }

  initialize(...args) {
    super.initialize(...args);

    // Page state, used for rerendering
    this.answerMargin = 10;
    this.hasAnswered = false;
    this.hasAnimated = false;
    this.$correctEl = null;
    this.$clickedEl = null;
    this.correctOption = null;
    this.clickedOption = null;
    this.questionOptions = [];

    $('#root-view .contentwrapper, #modalview .modal-content-wrapper').removeClass('matchwrap');
  }


  render() {
    this.questionOptions = this.variant.options;

    this.setupActionBarWithConfidence();

    // if maintainOptionOrder flag is set to true, t
    // then order the fields by ordinalNumber (0 based)
    // else just do a random sort
    if (this.variant.maintainOptionOrder === true) {
      this.questionOptions = _.sortBy(this.questionOptions, (questions) => {
        return questions.locationOrdinal;
      });
    } else {
      this.questionOptions.sort(() => {
        return 0.5 - Math.random();
      });
    }

    this.$el.html(this.template({
      variant: this.variant,
      options: this.questionOptions,
      questionOrdinal: this.questionOrdinal
    }));
    this.$('a[href^=\'http\']').attr('target', '_blank');

    // Render answer area
    for (let i = 0; i < this.questionOptions.length; i++) {
      const option = this.questionOptions[i];
      const html = this.rowTemplate({
        i,
        optionSize: this.questionOptions.length,
        option,
        optionText: this._generateOptionText(option)
      });
      this.$('.answerwrapper').append(html);
    }
    this.$('.answerwrapper .answerradio').first()
      .attr('tabindex', '0');
    return this;
  }

  optionById(id) {
    return _(this.questionOptions).find((option) => {
      return option.id === id;
    });
  }

  // Animates the Answer selection action
  // Depends on the state this.$clickedEl and this.$correctEl being set
  viewDidAppear(...args) {
    // Window events
    $(document).on('keydown', this.keyDown);

    logging.info('MultipleChoiceQuestionPage - viewDidAppear');
    this._focusQuestionHeading();
    super.viewDidAppear(...args);
  }

  clickAnswer(e) {
    // blur the answer radio button on click, we don't want the :focus styling
    $(e.currentTarget).trigger('blur');
  }

  selectAnswer(e) {
    if ($(e.target).is('.zoom-overlay, .zoom-icon, .zoom-image-wrap, img') || this.hasAnswered) {
      return;
    } // don't run the select event again.
    this.$('.answer').removeClass('selected choice')
      .find('.answerradio')
      .attr('aria-checked', 'false')
      .attr('tabindex', '-1');
    $(e.currentTarget).addClass('selected choice')
      .find('.answerradio')
      .attr('aria-checked', 'true')
      .attr('tabindex', '0')
      .trigger('focus');
    this.ui.accessibilityNote.hide();

    this.showActionBarWithConfidence();
  }

  onSubmit(confidenceLevel) {
    if (this.hasAnswered || (this.$('.answer.selected').length !== 1)) {
      return;
    }
    this.hasAnswered = true;
    this.$clickedEl = this.$('.answer.selected');
    const questionOptionId = parseInt(this.$clickedEl.attr('data-answer-option'), 10);
    this.clickedOption = this.optionById(questionOptionId);

    // Submit answer and load next activity JSON
    const activityBody = {
      questionVariantId: this.activity.get('body').question.variants[0].id,
      questionOptionId,
      confidenceLevel
    };

    const { hideAnswersAndReason } = this.options;
    this.activity.setAction('ANSWERQUESTION', activityBody, {
      success: (actionResponse) => {
        this.hasGotServerResponse = true;
        if (hideAnswersAndReason) {
          this.next();
        } else {
          const question = actionResponse.answer.question.variants[0];
          const {
            options
          } = question;

          for (const option of options) {
            const $optionEl = this.$(`.answer[data-answer-option='${ option.id }']`);

            // Set question explanation
            if ((option.reasonImg != null) || ((option.reasonText != null) && (option.reasonText.length > 0))) {
              this._addReasonMarkup(option);
              this._updateAnswerAriaLabelForReasonText($optionEl.find('.answerradio'), option.id);
            }

            // Set the correct answer
            if (option.correct) {
              this.$correctEl = $optionEl;
              this.correctOption = this.optionById(option.id);
            }
          }

          this.createReason(question);

          // Animate
          this.$correctEl.find('.optionReasonText').show();
          this.$correctEl.addClass('correct');
          this._updateAnswerAriaLabelForCorrectness(this.$correctEl.find('.answerradio'), true);
          this.$clickedEl.find('.optionReasonText').show();
          this.correct = false;
          // Animate correct answer
          const $allAnswers = this.$('.answer');
          $allAnswers.removeClass('choice');
          $allAnswers.find('.answerradio').attr('aria-disabled', 'true')
            .attr('tabindex', '-1');

          const points = actionResponse.pointsEarned != null ? actionResponse.pointsEarned : 0;

          // Add "wrong" class
          const $wrongAnswers = $allAnswers.not(this.$correctEl);
          $wrongAnswers.addClass('notselected wrong');

          if (this.$correctEl.is(this.$clickedEl)) {
          // Correct answer
            this.correct = true;
            this._changeCoachCorrect();

            if (this.gameManager != null) {
              this.gameManager.questionAnsweredCorrect();
            }
          } else {
          // Incorrect answer
            this.$clickedEl.addClass('incorrect');
            this._updateAnswerAriaLabelForCorrectness(this.$clickedEl.find('.answerradio'), false);
            this._changeCoachIncorrect();

            if (this.gameManager != null) {
              this.gameManager.questionAnsweredIncorrect();
            }
          }

          this.showPointsArea(this.correct, points);
          window.apps.auth.session.user.addPoints(points);

          this.setupAndShowActionBarWithContinue();
          this.scrollResultBannerIntoView(this.setFocusOnCorrectIncorrect);
        }
      }
    });
  }

  _addReasonMarkup(option) {
    const parent = this.$(`.answer[data-answer-option='${ option.id }'] .table-helper`);
    const reasonImgContainerClass = (option.reasonImg != null) ? 'answerimage' : '';

    const reasonHtml = this.explainTemplate({
      option,
      reasonImgContainerClass
    });

    parent.append(reasonHtml);

    if (option.reasonImg != null) {
      this._imageMediaInfo[option.reasonImg.preferred.id] = option.reasonImg.preferred;
      this._imageViewers[option.reasonImg.preferred.id] = ImageViewerFactory.createViewerInstance({
        media: option.reasonImg.preferred,
        $el: parent.find('.reason-image')
      });
      this.listenTo(this._imageViewers[option.reasonImg.preferred.id], 'image:loaded', this.onReasonImgLoad);
      this._imageViewers[option.reasonImg.preferred.id].render();
    }
  }

  onReasonImgLoad() {
    this.triggerAdjustment();
    this.trySetFocusOnContinue();
  }

  keydownAnswer(e) {
    if ([KeyCode.LEFT, KeyCode.UP].includes(e.which)) {
      this._selectPreviousAnswer();
    } else if ([KeyCode.RIGHT, KeyCode.DOWN].includes(e.which)) {
      this._selectNextAnswer();
    } else if ([KeyCode.ENTER, KeyCode.SPACE].includes(e.which)) {
      this.selectAnswer({
        currentTarget: $(e.target).closest('.answer')
      });
    }
  }

  onNext() {
    $(document).off('keyDown');

    if (_.isFunction(this.options.complete)) {
      this.options.complete();
    }
  }

  _changeCoachCorrect() {
    const altText = I18n.t('coaches.correctAnswer');

    this.$('.bonuscharacter div, .hinttext').removeClass('ponder')
      .addClass('correct');

    this.$('.bonuscharacter div').attr('aria-label', altText);
  }

  _changeCoachIncorrect() {
    const altText = I18n.t('coaches.incorrectAnswer');

    this.$('.bonuscharacter div, .hinttext').removeClass('ponder')
      .addClass('incorrect');

    this.$('.bonuscharacter div').attr('aria-label', altText);
  }

  _selectPreviousAnswer() {
    this._selectAdjacentAnswer((index) => {
      return index - 1;
    });
  }

  _selectNextAnswer() {
    this._selectAdjacentAnswer((index) => {
      return index + 1;
    });
  }

  _selectAdjacentAnswer(updateIndexFn) {
    const answers = this.$('.answer');
    const answersLength = answers.length;
    let selectedAnswer = this.$('.answerradio:focus').closest('.answer');
    if (selectedAnswer.length === 0) {
      selectedAnswer = this.$('.answer.selected');
    }

    if (answersLength === 0) {
      // ES-Lint no-empty fix
    } else if (answersLength === 1 || selectedAnswer.length === 0) {
      this.selectAnswer({
        currentTarget: answers.first()
      });
    } else {
      const index = selectedAnswer.data('answer-index');
      let newIndex = updateIndexFn(index);
      if (newIndex < 0) {
        newIndex = answersLength - 1;
      } else if (newIndex >= answersLength) {
        newIndex = 0;
      }
      this.selectAnswer({
        currentTarget: answers.get(newIndex)
      });
    }
  }

  _updateAnswerAriaLabelForCorrectness($answerRadio, isCorrect) {
    const curLabel = $answerRadio.attr('aria-label');
    const newLabel = isCorrect
      ? I18n.t('question.accessibility.correctAnswer') + curLabel
      : I18n.t('question.accessibility.incorrectAnswer') + curLabel;
    $answerRadio.attr('aria-label', newLabel);
  }

  _updateAnswerAriaLabelForReasonText($answerRadio, questionId) {
    const curLabel = $answerRadio.attr('aria-label');
    $answerRadio.attr('aria-label', `${ curLabel } reason-${ questionId }`);
  }

  _focusQuestionHeading() {
    this.$('.questionask').attr('tabindex', '-1')
      .trigger('focus');
  }
}

module.exports = MultipleChoiceQuestionPage;
