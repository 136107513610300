// Module
var code = `<div class="tile-top">
	<div class="topic">
		<span class="hidden icon" role="img"></span>
		<p class="topic-name qa-topic-name"><%- program.name %></p>
	</div>

	<div class="topic-statuses">

		<div class="grade">
			<p class="grade-value"></p>
			<p class="grade-text"></p>
		</div>

		<div class="lock-icon"></div>

	</div>

</div>

<div class="tile-bottom-wrapper">
	<div class="tile-bottom">

		<div class="tile-meta scheduled-exam-tile-meta">
			<span class="first content"></span>
			<span class="mid qa-rewards rewards"></span>
			<span class="last qa-attempts attempts"></span>
		</div>

		<div class="action-buttons-wrapper">
			<%= axButton({
				className: 'retake-button js-retake-button qa-retake-button hidden',
				ariaLabel: t('assessments.select.retake') + ' ' + program.name,
				label: t('assessments.select.retake'),
				iconClassLeft: 'icon-play',
				branded: true
			}) %>
			<%= axButton({
				className: 'start-next-button js-start-next-button qa-start-next-button hidden',
				ariaLabel: t('assessments.select.startNext') + ' ' + program.name,
				label: t('assessments.select.startNext'),
				iconClassLeft: 'icon-play',
				branded: true
			}) %>
	</div>
</div>
`;
// Exports
module.exports = code;