import {
  AxIcon,
  AxIconButton,
  getIconButtonSizeStyles
} from '@common/modules/react/themes/components';

import I18n from '@common/libs/I18n';
import { useTheme } from '@mui/material';

type AxDialogCloseButtonProps = {
  qaClassName: `qa-${ string }`
  onBack?: () => void
};

export default function AxDialogBackButton(props: AxDialogCloseButtonProps) {
  const {
    onBack,
    qaClassName
  } = props;

  const theme = useTheme();
  const buttonSize = 'medium';

  return <AxIconButton
    className={ qaClassName }
    size={ buttonSize }
    aria-label={ I18n.t('general.back') }
    onClick={ onBack }
    sx={{
      borderColor: 'transparent',
      '&:hover': {
        borderColor: 'transparent'
      },
      marginLeft: `-${ getIconButtonSizeStyles(buttonSize, theme).paddingLeft }`
    }}
  >
    <AxIcon iconName='icon-chevron_left' />
  </AxIconButton>;
}
