class AbstractProgressConfig {

  isValid() {
    throw new Error('"isValid" needs to be implemented by the subclass');
  }

  getSegmentLabelText() {
    throw new Error('"getSegmentLabelText" needs to be implemented by the subclass');
  }

  getSegmentValue() {
    throw new Error('"getSegmentValue" needs to be implemented by the subclass');
  }

  getMarkerOptions() {
    throw new Error('"getMarkerOptions" needs to be implemented by the subclass');
  }

  containsActiveActivity() {
    throw new Error('"containsActiveActivity" needs to be implemented by the subclass');
  }
}

module.exports = AbstractProgressConfig;
