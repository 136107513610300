const logging = require('logging');
const $os = require('detectOS');
const _ = require('underscore');

const QuestionView = require('@training/apps/training/views/activities/pages/questions/QuestionView');
const I18n = require('@common/libs/I18n');
const KeyCode = require('@common/data/enums/KeyCode');
require('@common/libs/behaviors/zoomoverlay/ZoomOverlay');

class SurveyQuestionPage extends QuestionView {
  constructor(...args) {
    super(...args);
    this.optionByIndex = this.optionByIndex.bind(this);
    this.next = this.next.bind(this);
    this.onNext = this.onNext.bind(this);
    this.template = _.tpl(require('@training/apps/training/templates/activities/pages/SurveyQuestionPage.html'));
    this.rowTemplate = _.tpl(require('@training/apps/training/templates/activities/pages/_survey_question_row.html'));
  }

  behaviors() {
    return {
      ZoomOverlay: {}
    };
  }

  events() {
    return {
      'click .answer': 'selectAnswer',
      'click .answerradio': 'clickAnswer',
      'keydown .answerradio': 'onKeydownAnswer',
      'focus .comment-wrapper textarea': 'onFocusTextarea',
      'blur .comment-wrapper textarea': 'onBlurTextarea'
    };
  }

  ui() {
    return {
      accessibilityNote: '#accessibility-note'
    };
  }

  initialize(...args) {
    super.initialize(...args);

    this.hasAnswered = false;
    this.questionOptions = [];

    this.questionOptions = [
      {
        id: 1,
        option: this.variant.answer1
      },
      {
        id: 2,
        option: this.variant.answer2
      },
      {
        id: 4,
        option: this.variant.answer4
      },
      {
        id: 5,
        option: this.variant.answer5
      }
    ];
    if (this.variant.allowAnswer3) {
      return this.questionOptions.splice(2, 0, {
        id: 3,
        option: this.variant.answer3
      });
    }

    return undefined;
  }

  getQuestionContent(activityModel) {
    return activityModel.get('body').question;
  }

  render() {
    let displayAsColumns, html, option, optionText, classname;
    this.$el.html(this.template({
      variant: this.variant,
      options: this.questionOptions,
      questionOrdinal: this.questionOrdinal
    }));

    // Render survey options
    for (let i = 0; i < this.questionOptions.length; i++) {
      option = this.questionOptions[i];
      displayAsColumns = Boolean($os.desktop);
      classname = 'survey-option';
      html = this.rowTemplate( _.extend(option, {
        label: i + 1,
        displayAsColumns,
        optionText,
        i,
        classname
      }));
      this.$('.answerwrappersize .answerwrapper').append(html);
    }

    // Render opt-out option
    html = this.rowTemplate({
      id: -1,
      i: -1,
      label: 0,
      option: I18n.t('question.surveyOptOut'),
      displayAsColumns: false,
      classname: 'opt-out-option'
    });
    this.$('.opt-out').append(html);

    // Keep a reference to comment for later, can't be done in initialize since
    // it gets rendered as part of the template if `allowComments` is true
    if (this.variant.allowComments) {
      this.$comment = this.$('textarea');
    }

    // Forces links that start with "http" to open in a new window/tab, this was
    // copied over from other question types and should be abstracted (TODO)
    // XXX: this probably doesn't work in-app on mobile so it should be rethought
    this.$('a[href^=\'http\']').attr('target', '_blank');

    this.$('.answerwrapper .answerradio').first()
      .attr('tabindex', 0);
    this.$('.opt-out .answerradio').attr('tabindex', 0);

    return this;
  }

  viewDidAppear(...args) {
    logging.info('SurveyQuestionPage - viewDidAppear');

    this.hideActionBar({animate: false});
    this.$('.questionask').attr('tabindex', -1)
      .trigger('focus');

    super.viewDidAppear(...args);
  }

  _createImageMediaInfo(...args) {
    super._createImageMediaInfo(...args);

    this.questionImg = this.activity.get('body').question.image;
    if (this.questionImg != null) {
      this._imageMediaInfo[this.questionImg.preferred.id] = this.questionImg.preferred;
    }
  }

  onFocusTextarea(e) {
    // This is a hack for IE6/7
    const $commentWrapper = $(e.target).parents('.comment-wrapper');
    if ($commentWrapper.length) {
      $commentWrapper.addClass('active');
    }
  }

  onBlurTextarea(e) {
    // This is a hack for IE6/7
    const $commentWrapper = $(e.target).parents('.comment-wrapper');
    if ($commentWrapper.length) {
      $commentWrapper.removeClass('active');
    }
  }

  clickAnswer(e) {
    // blur the answer radio button on click, we don't want the :focus styling
    $(e.currentTarget).trigger('blur');
  }

  onKeydownAnswer(e) {
    if ([KeyCode.LEFT, KeyCode.UP].includes(e.which)) {
      this._selectPreviousAnswer();
    } else if ([KeyCode.RIGHT, KeyCode.DOWN].includes(e.which)) {
      this._selectNextAnswer();
    } else if ([KeyCode.ENTER, KeyCode.SPACE].includes(e.which)) {
      this.selectAnswer({
        currentTarget: $(e.target).closest('.answer')
      });
    }
  }

  selectAnswer(e) {
    const $currentTarget = $(e.currentTarget);
    if (!$currentTarget.length) {
      return false;
    }

    this.$('.answer').removeClass('selected choice')
      .find('.answerradio')
      .attr('aria-checked', 'false');
    this.$('.answer.survey-option').find('.answerradio')
      .attr('tabindex', -1);
    $currentTarget.addClass('selected choice').find('.answerradio')
      .attr('aria-checked', 'true')
      .attr('tabindex', 0)
      .trigger('focus');

    // Allow the above radiogroup to become tabbable when the opt-out radio is clicked
    if ($currentTarget.hasClass('opt-out-option')) {
      this.$('.answer.survey-option').first()
        .find('.answerradio')
        .attr('tabindex', 0);
    }

    // Disable the comment textarea if opt-out is selected
    const questionOptionId = parseInt($currentTarget.attr('data-answer-option'), 10);
    const isOptOut = questionOptionId < 0;
    if (this.$comment != null) {
      this.$comment.prop('disabled', isOptOut);
    }

    // Setup and show action bar only the first time an answer is selected
    if (!this.hasAnswered) {
      this.setupAndShowActionBarWithContinue({animate: true});
    }

    this.hasGotServerResponse = true;
    this.hasAnswered = true;

    this.ui.accessibilityNote.hide();
    return undefined;
  }

  _selectAdjacentAnswer(updateIndexFn) {
    const answers = this.$('.answer.survey-option');
    const answersLength = answers.length;
    let selectedAnswer = this.$('.answerradio:focus').closest('.answer.survey-option');
    if (selectedAnswer.length === 0) {
      selectedAnswer = this.$('.answer.selected');
    }

    if (answersLength === 0) {
      // do nothing
    } else if (answersLength === 1 || selectedAnswer.length === 0) {
      this.selectAnswer({
        currentTarget: answers.first()
      });
    } else {
      const index = selectedAnswer.data('answer-index');
      let newIndex = updateIndexFn(index);
      if (newIndex < 0) {
        newIndex = answersLength - 1;
      } else if (newIndex >= answersLength) {
        newIndex = 0;
      }
      this.selectAnswer({
        currentTarget: answers.get(newIndex)
      });
    }
  }

  _selectPreviousAnswer() {
    this._selectAdjacentAnswer((index) => {
      return index - 1;
    });
  }

  _selectNextAnswer() {
    this._selectAdjacentAnswer((index) => {
      return index + 1;
    });
  }

  optionByIndex(index) {
    return this.questionOptions[index];
  }

  next() {
    if (apps.base.checkDoubleSubmit() || this.nextClicked || !this.hasGotServerResponse || !this.hasAnswered) {
      return false;
    }

    const $clickedEl = this.$('.answer.selected');

    if (!$clickedEl.length) {
      return false;
    }

    this.nextClicked = true;
    const questionOptionId = parseInt($clickedEl.attr('data-answer-option'), 10);
    const isOptOut = questionOptionId < 0;

    const activityBody = {
      duration: this.activity.activityTimer.elapsedTime()
    };

    if (!isOptOut) {
      // `answerIdx` is set only if the action isn't opt-out
      activityBody['answerIdx'] = questionOptionId;
      // Submit a comment if `allowComments` is true and the user didn't choose to opt-out and a comment was input
      if (this.activity.get('body').question.allowComments && this.$comment.val().trim().length > 0) {
        activityBody['comment'] = this.$comment.val().trim();
      }
    }

    this.listenToOnce(this.activity, 'error:action', () => {
      this.nextClicked = false;
    });

    const actionType = isOptOut ? 'OPTOUT' : 'ANSWERSURVEYQUESTION';
    this.activity.setAction(actionType, activityBody, {
      success: (response) => {
        // Give the user their points unless they chose to opt-out
        if (!isOptOut && response.pointsEarned != null) {
          if (app.layout.totalBalance != null) {
            window.app.layout.totalBalance.add(response.pointsEarned);
          }
          window.apps.auth.session.user.addPoints(response.pointsEarned);
        }

        // Need to tell the game manager that the question was answered.
        // The design decision is to treat all survey answers as "incorrect" so
        // as not to give extra time for correct answer during a game.
        if (this.gameManager != null) {
          this.gameManager.questionAnsweredIncorrect();
        }

        if (_.isFunction(this.options.complete)) {
          this.options.complete();
        }

        this.hideActionBar();
      }
    });

    return undefined;
  }
}

module.exports = SurveyQuestionPage;
