const ProgressMarkerOptions = require('@training/apps/training/assessmentProgress/ProgressMarkerOptions');
const { createProgressMarkerViewConfig } = require('@training/apps/training/assessmentProgress/ProgressViewConfigFactory');

class EndMarkerBuilder {
  constructor(assessment) {
    if (assessment.activities.nextActivity()) {
      this.buildOptions = {};
    } else if (assessment.isGradable()) {
      const passed = assessment.assessmentResult.get('passed');
      if (passed === true) {
        this.buildOptions = ProgressMarkerOptions.Done;
      } else if (passed === false) {
        this.buildOptions = ProgressMarkerOptions.Failed;
      } else {
        this.buildOptions = {};
      }
    } else {
      this.buildOptions = ProgressMarkerOptions.Done;
    }
  }

  build() {
    return createProgressMarkerViewConfig(this.buildOptions);
  }
}

module.exports = EndMarkerBuilder;
