const Question = {
  iconState: 'active',
  iconClass: 'create-question-nobg'
};

const Video = {
  iconState: 'active',
  iconClass: 'play'
};

const Module = {
  iconState: 'active',
  iconClass: 'folder_close'
};

const Done = {
  iconState: 'active',
  iconClass: 'ok'
};

const Failed = {
  iconState: 'failed',
  iconClass: 'remove'
};

module.exports = {
  Question,
  Video,
  Module,
  Done,
  Failed
};

