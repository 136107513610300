import {
  FormGroup as MuiFormGroup,
  styled,
  useTheme,
  type FormGroupProps as MuiFormGroupProps
} from '@mui/material';
import React, {
  forwardRef,
  isValidElement,
  type Ref
} from 'react';

import { extractFragmentChildren } from '@common/libs/helpers/app/ReactHelpers';


type AxFormGroupProps = MuiFormGroupProps;

const styledOptions = {
  name: 'AxFormGroup'
};

const StyledAxFormGroup = styled(MuiFormGroup, styledOptions)<AxFormGroupProps>(() => {
  return {};
});


/**
 * AxFormGroup is a layout component in Material UI that groups form controls
 * like checkboxes, switches, or radio buttons.
 *
 * It helps organize form elements either vertically (default) or horizontally
 * when you set the `row` prop.
 *
 * Use this component when you have multiple related form controls, and you
 * want to group them together for better structure and accessibility.
 *
 * @example
 * // A vertical group of checkboxes
 * <AxFormGroup>
 *   <AxFormControlLabel control={<AxCheckbox />} label="Option 1" />
 *   <AxFormControlLabel control={<AxCheckbox />} label="Option 2" />
 * </AxFormGroup>
 *
 * @example
 * // A horizontal group of checkboxes
 * <AxFormGroup row>
 *   <AxFormControlLabel control={<AxCheckbox />} label="Option 1" />
 *   <AxFormControlLabel control={<AxCheckbox />} label="Option 2" />
 * </AxFormGroup>
 *
 */
export const AxFormGroup = forwardRef(({
  ...otherProps
}: AxFormGroupProps, ref: Ref<HTMLDivElement>) => {
  const theme = useTheme();

  const FormGroupProps: AxFormGroupProps = {
    ...otherProps
  };

  return (
    <StyledAxFormGroup { ...FormGroupProps } ref={ ref }>
      {
        // As we removed the margin in form inputs we need to add it here
        // if children are provided and the row prop is set, add spacing between.
        otherProps.children && otherProps.row ? (
          extractFragmentChildren(otherProps.children).map((child, index) => {
            return (
              isValidElement(child) ? React.cloneElement(child, {
                key: index,
                ...child.props,
                sx: {
                  marginRight: theme.uiKit.spacingM,
                  ...child.props.sx
                }
              }) : child
            );
          })
        ) : (
          otherProps.children
        ) }
    </StyledAxFormGroup>
  );
});

export default AxFormGroup;
