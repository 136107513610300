// Module
var code = `<div class="tile-top">
  <div class="topic">
    <span class="hidden icon" role="img"></span>
    <h2 class="topic-hierarchy-details"><%- t('start.assessmentType.evaluation') %></h2>
    <h3 class="topic-name qa-topic-name" title="<%- evaluation.name %>"><%- evaluation.name %></h3>
  </div>

  <div class="topic-statuses">

    <div class="grade">
      <p class="grade-value"></p>
      <p class="grade-text"></p>
    </div>

    <div class="lock-icon"></div>

  </div>

</div>

<div class="tile-bottom-wrapper">
  <div class="tile-bottom">

    <div class="tile-meta evaluation-tile-meta">
      <span class="first content"></span>
      <span class="last qa-due due"></span>
			<span class="last qa-rewards rewards" ></span>
    </div>

    <div class="action-buttons-wrapper">
      <%= axButton({
        className: 'start-next-button js-start-next-button qa-start-next-button hidden',
        ariaLabel: t('assessments.select.startNext') + ' ' + evaluation.name,
        label: t('assessments.select.startNext'),
        iconClassLeft: 'icon-play',
        branded: true
      }) %>
    </div>

  </div>
</div>
`;
// Exports
module.exports = code;