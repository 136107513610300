// Module
var code = `<h1 class="page-header">
  <div class="ax-container">
    <div class="page-header__content">
      <span class="page-header__icon icon-profile" aria-hidden="true"></span>
      <div class="page-header__title"><%- t('settings.userTitle')%></div>
    </div>
  </div>
</h1>

<div class="user-profile ax-grid">
  <form id="user-form" class="ax-grid__col--12 ax-grid__col--m-8 ax-grid__col--l-6">
    <fieldset>
      <% if (canHaveImage && showAttachments) { %>
        <div class="media-image-thumbnail" tabindex="-1" role="region" aria-label="<%- t('stats.avatar') %>">
          <% if (\$os.desktop && isSupported && !\$os.ipad) { %>
            <div class="remove-wrap">
              <button type="button" class="remove" aria-label="<%- t('user.adjustPhoto.remove') %>"></button>
            </div>
            <button type="button" class="image-thumbnail-overlay">
              <div class="add-pic camera adjust-photo ax-font ax-font__caption"><%-  t('user.imageUploader.title')  %></div>
              <div class="add-pic camera choose-photo ax-font ax-font__caption"><%-  t('user.imageUploader.choosePhoto.button.title')  %></div>
            </button>
            <div class="media-thumbnail-desktop" aria-hidden="true"></div>
          <%} else if ((!\$os.desktop || \$os.ipad) && isSupported) { %>
            <button type="button" class="image-thumbnail-overlay">
              <div class="add-pic adjust-photo camera ax-font ax-font__caption"><%-  t('user.imageUploader.adjustPhoto.button.title')  %></div>
              <div class="add-pic choose-photo camera ax-font ax-font__caption"><%-  t('user.imageUploader.choosePhoto.button.title')  %></div>
            </button>
            <div class="media-thumbnail"
              data-field="profileImage"
              data-editor="MediaThumbnail"
              data-options="mediaThumbnailOptions"
              maxHeight="200"
              maxWidth="200">
            </div>
          <% } %>
        </div>
      <% } %>

      <% if (canHaveNickName) { %>
        <div class="ax-grid">
          <div class="ax-grid__col--12 ax-grid__col--m-10">
            <%= axInput({
              autocomplete: 'off',
              classNameInput: 'qa-input-nickname',
              data: {
                field: 'nickname'
              },
              fullWidth: true,
              id:'name',
              label: t('user.nickname')
            }) %>
          </div>
        </div>
      <% } %>

      <% if (canHaveBirthYear) { %>
        <div class="ax-grid">
          <div class="ax-grid__col--12 ax-grid__col--m-10">
            <%= axSelect({
              classNameSelect: 'qa-birth-year-select',
              data: {
                field: 'birthYear',
                options: 'birthYearSelectList',
                shallow: 'true'
              },
              fullWidth: true,
              id: 'birth-date',
              label: t('user.birthYear.title'),
              name: 'birth-date'
            }) %>
          </div>
        </div>
      <% } %>

      <% if (canHaveGender) { %>
        <div class="ax-grid">
          <div class="ax-grid__col--12 ax-grid__col--m-10">
            <%= axSelect({
              classNameSelect: 'qa-gender-select',
              data: {
                field: 'gender',
                options: 'genderSelectList',
                shallow: 'true'
              },
              fullWidth: true,
              id: 'gender',
              label: t('user.gender.title'),
              name: 'gender'
            }) %>
          </div>
        </div>
      <% } %>
    </fieldset>
  </form>
</div>
`;
// Exports
module.exports = code;