import {
  AxIcon,
  type AxIconProps
} from '@common/modules/react/themes/components';

export default function LinkIcon(props: AxIconProps) {

  return (
    <AxIcon
      className='icon-link'
      { ...props }
    />
  );
}

