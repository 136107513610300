// Module
var code = `<!--QUESTION-->
<div class="fitb">
  <div class="question-number-header"><%- t('question.questionNumberHeader', { questionOrdinal }) %></div>
  <!--ANSWERS-->
  <div class="questionarea">
    <div class="answerwrapper">

      <div class="fillintheblanks">

      <% if (variant.questionImg) { %>
        <div class="fitbleftside">
          <button class="questionimage imgwrap zoom-image-wrap" data-media-id="<%- variant.questionImg.preferred.id %>"></button>
        </div>
      <% } %>
        <div class="fitbrightside">
          <div class="questionask" aria-label="<%- screenReaderStr %>"><%= questionHtml %></div>
        </div>

      </div>

    </div>
    <div class="bonuscharacter" <% if (bonuscharacter === 'NoCoach') { %>aria-hidden="true" role="presentation"<% } %>>
      <div role="img" class="<%- bonuscharacter %> ponder" aria-label="<%- t('coaches.thinking') %>"></div>
    </div>
  </div>

  <p id="accessibility-note" class="off-screen"><%- t('question.accessibility.fillInBlankInstructions') %></p>

  <div class="hinttext">
    <div class="clearfix question-instruction" role="heading" aria-level="3" aria-live="polite"></div>
  </div>

  <p id="accessibility-correct-note" tabindex="-1" class="hidden off-screen-static"></p>
</div>
`;
// Exports
module.exports = code;