import { createAppStoreConfigurator } from '@common/configs/redux';
import { logErrorAction } from '@training/apps/common/sagas/sagaActions';
import {
  useDispatch,
  useSelector,
  type TypedUseSelectorHook
} from 'react-redux';
import createSagaMiddleware from 'redux-saga';

// Types and slice implementations for initial store state
import {
  default as AuthSliceImpl,
  type AuthSlice
} from '@common/modules/auth/AuthSlice';

// Types ONLY for Lazy loaded slices
// ONLY types from Training app folder
import type { TaskApi as OpsTaskApi } from '@common/components/opsTasks/requests';
import type { MainStatsApi } from '@common/components/stats/MainStatsApiService';
import type { ProfanityFilterApi } from '@common/modules/profanityFilter/ProfanityFilterApi';
import type { SimpleFileApi } from '@common/services/simpleFile/SimpleFileApiService';
import type { ChatApi } from '@training/apps/chat/ChatApiService';
import type { ChatSlice } from '@training/apps/chat/chatSlice';
import type { SurveyAnswersApi } from '@training/apps/common/api/SurveyAnswerApi';
import type { FeatureSlice } from '@training/apps/common/slices/featureSlice';
import type { PageViewSlice } from '@training/apps/common/slices/pageViewSlice';
import type { RegistrationSlice } from '@training/apps/common/slices/registrationSlice';
import type { SessionSlice } from '@training/apps/common/slices/sessionSlice';
import type { UserRegistrationGroupsSlice } from '@training/apps/common/slices/userRegistrationGroupsSlice';
import type { UserSlice } from '@training/apps/common/slices/userSlice';
import type { UserStatusSlice } from '@training/apps/common/slices/userStatusSlice';
import type { ConversationalSearchApi } from '@training/apps/conversationalSearch/ConversationalSearchApiService';
import type { ConversationalSearchSlice } from '@training/apps/conversationalSearch/conversationalSearchSlice';
import type { LeaderboardCardApi } from '@training/apps/home/hubPreviews/leaderboard/LeaderboardApiService';
import type { AchievementsCardApi } from '@training/apps/home/performance/achievements/services/AchievementsCardApiService';
import type { RecognitionPinsApi } from '@training/apps/recognitions/api/RecognitionPinsApiService';
import type { TaskApi } from '@training/apps/tasks/requests/TaskApi';
import type { DueTasksSlice } from '@training/apps/tasks/slices/dueTasksSlice';
import type { SuggestedTasksSlice } from '@training/apps/tasks/slices/suggestedTasksSlice';
import type { TaskAssignableUsersSlice } from '@training/apps/tasks/slices/taskAssignableUsersSlice';
import type { TaskAssignmentSlice } from '@training/apps/tasks/slices/taskAssignmentSlice';
import type { TaskCompletionSlice } from '@training/apps/tasks/slices/taskCompletionSummarySlice';
import type { TaskDetailsSlice } from '@training/apps/tasks/slices/taskDetailsSlice';
import type { TaskGroupSlice } from '@training/apps/tasks/slices/taskGroupSlice';
import type { TaskSlice } from '@training/apps/tasks/slices/taskSlice';
import type { TaskTimelineSlice } from '@training/apps/tasks/slices/taskTimelineSlice';
import type { TimelineApi } from '@training/apps/timeline/api';

export const sagaMiddleware = createSagaMiddleware();

const configureAppStore = createAppStoreConfigurator<[
  AuthSlice
], [
  SurveyAnswersApi,
  TaskApi,
  OpsTaskApi,
  MainStatsApi,
  AchievementsCardApi,
  LeaderboardCardApi,
  ChatApi,
  SimpleFileApi,
  RecognitionPinsApi,
  ConversationalSearchApi,
  ProfanityFilterApi,
  TimelineApi,
  ConversationalSearchSlice,
  ChatSlice,
  FeatureSlice,
  PageViewSlice,
  RegistrationSlice,
  SessionSlice,
  UserRegistrationGroupsSlice,
  UserSlice,
  UserStatusSlice,
  DueTasksSlice,
  SuggestedTasksSlice,
  TaskAssignableUsersSlice,
  TaskAssignmentSlice,
  TaskCompletionSlice,
  TaskDetailsSlice,
  TaskGroupSlice,
  TaskSlice,
  TaskTimelineSlice
]>(
  AuthSliceImpl
);

export const {
  store: trainingStore,
  injectSlice
} = configureAppStore({
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [logErrorAction.type]
      }
    })
      .prepend(sagaMiddleware);
  }
});

export type TrainingRootState = ReturnType<typeof trainingStore.getState>;
export type TrainingAppDispatch = typeof trainingStore.dispatch;

export const useTrainingAppSelector: TypedUseSelectorHook<TrainingRootState> = useSelector;
export const useTrainingAppDispatch: () => TrainingAppDispatch = useDispatch;
