import UiKit from '@common/modules/react/themes/UiKit';
import { useTheme } from '@mui/material';

export const useDateTimeStyles = () => {
  const theme = useTheme();

  return {
    '.MuiPickersDay-root': {
      '&.Mui-selected': {
        backgroundColor: theme.uiKit.datePicker.selectedDay.backgroundColor,
        color: theme.uiKit.datePicker.selectedDay.color,
        '&:focus': {
          backgroundColor: theme.uiKit.datePicker.selectedDay.backgroundColorHover,
          color: UiKit.datePicker.selectedDay.color
        },
        '&:hover': {
          backgroundColor: theme.uiKit.datePicker.selectedDay.backgroundColorHover,
          color: theme.uiKit.datePicker.selectedDay.color
        }
      }
    },
    '.MuiPickersDay-today': {
      borderColor: theme.uiKit.datePicker.today.borderColor,
      backgroundColor: theme.uiKit.datePicker.today.backgroundColor,
      '&:focus': {
        borderColor: theme.uiKit.datePicker.today.borderColor,
        backgroundColor: theme.uiKit.datePicker.today.backgroundColorHover
      },
      '&:hover': {
        borderColor: theme.uiKit.datePicker.today.borderColor,
        backgroundColor: theme.uiKit.datePicker.today.backgroundColorHover
      }
    },
    '.MuiMultiSectionDigitalClockSection-item': {
      '&.Mui-selected': {
        backgroundColor: theme.uiKit.datePicker.selectedDay.backgroundColor,
        color: theme.uiKit.datePicker.selectedDay.color,
        '&:focus': {
          backgroundColor: theme.uiKit.datePicker.selectedDay.backgroundColorHover,
          color: theme.uiKit.datePicker.selectedDay.color
        },
        '&:hover': {
          backgroundColor: theme.uiKit.datePicker.selectedDay.backgroundColorHover,
          color: theme.uiKit.datePicker.selectedDay.color
        }
      }
    },
    '& .MuiClock-pin, & .MuiClockPointer-root': {
      backgroundColor: theme.uiKit.datePicker.selectedDay.backgroundColor
    },
    '& .MuiClockPointer-thumb': {
      backgroundColor: theme.uiKit.datePicker.selectedDay.backgroundColor,
      borderColor: theme.uiKit.datePicker.selectedDay.backgroundColor
    },
    '& .MuiClock-clock': {
      '& .MuiClockNumber-root.Mui-selected': {
        backgroundColor: theme.uiKit.datePicker.selectedDay.backgroundColor,
        color: theme.uiKit.datePicker.selectedDay.color
      }
    },
    // bring focus to the arrow switcher buttons and year view buttons
    '.Mui-focusVisible': {
      backgroundColor: theme.palette.action.hover,
      ...theme.mixins.componentKeyboardFocusStyles('inset')
    }
  };
};
