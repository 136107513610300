import I18nString from '@common/components/I18nString/I18nString';
import TooltipButton from '@common/components/tooltipButton/TooltipButton';
import I18n from '@common/libs/I18n';
import { AxStack } from '@common/modules/react/themes/components';
import FieldRequiredAsterisk from '@common/modules/react/themes/components/custom/atoms/FieldRequiredAsterisk';
import { useTheme } from '@mui/material/styles';

export interface FieldLabelWithExtrasProps {
  labelKey: string,
  required?: boolean,
  tooltipKey?: string,
  alignTooltip?: 'start' | 'center' | 'end'
}

export default function FieldLabelWithExtras(props: FieldLabelWithExtrasProps): JSX.Element {
  const {
    labelKey,
    required,
    tooltipKey,
    alignTooltip = 'center'
  } = props;

  const theme = useTheme();

  return (
    <AxStack
      direction='row'
      alignItems={ alignTooltip }
      sx={{
        marginBottom: theme.uiKit.spacingM
      }}
    >
      <I18nString i18nKey={ labelKey } />
      { required && <FieldRequiredAsterisk /> }
      {
        (tooltipKey && tooltipKey !== '')
        && <TooltipButton
          tooltipProps={{
            title: I18n.t(tooltipKey)
          }}
          buttonProps={{
            iconName: 'icon-create-question',
            sx: {
              marginLeft: theme.uiKit.spacingXS
            }
          }}
        />
      }
    </AxStack>
  );
}

