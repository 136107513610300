const _ = require('underscore');
const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');
const { ItemView } = require('Marionette');

require('jquery.velocity');

class ProgressBar extends ItemView {
  getTemplate() {
    return `\
      <div class="progress-bar">
        <div class="progress-bar-inset <%- completeClass %>" role="progressbar"></div>
        <div class="progress-bar-marks hidden" role="presentation" aria-hidden="true"></div>
      </div>\
    `;
  }

  ui() {
    return {
      inset: '.progress-bar-inset',
      marks: '.progress-bar-marks'
    };
  }

  constructor(options = {}) {
    super(options);

    this.lineCount = options.lineCount;
    this.ANIMATION_DURATION = 300;
    this._viewState = new Backbone.Model();

    this.setOptions({
      value: options.value != null ? options.value : 0,
      animate: options.animate != null ? options.animate : false,
      barColor: options.barColor != null ? options.barColor : ''
    });
  }

  templateHelpers() {
    return {
      lineCount: this.lineCount,
      completeClass: (this.getOption('value') === 100) ? 'progress-bar-inset--complete' : ''
    };
  }

  onRender() {
    if (this.lineCount > 0) {
      this.ui.marks.removeClass('hidden');
      this.ui.marks.attr('style', `--line-count: ${ this.lineCount };`);
    }

    this._updateProgress();
    this.listenTo(this._viewState, 'change', this._updateProgress);
  }

  _updateProgress() {
    if (this.shouldAnimate() && this.isVisible()) {
      this._animateProgress();
    } else {
      this._setProgress();
    }
  }

  setValue(value = 0, animate = false) {
    this.setOptions({
      value,
      animate
    });
  }

  setBarColor(barColor = '') {
    return this.setOptions({ barColor });
  }

  setOptions(options = {}) {
    this._viewState.set(_.pick(options, 'value', 'animate', 'barColor'));
  }

  shouldAnimate() {
    return Boolean(this._viewState.get('animate'));
  }

  getValue() {
    return this._viewState.get('value');
  }

  getBarColor() {
    return this._viewState.get('barColor');
  }

  _setProgress() {
    this.ui.inset.attr('aria-label', I18n.t('general.currentCompletion'));
    this.ui.inset.attr('aria-valuenow', Math.round(this._viewState.get('value')));
    this.ui.inset.css(this._getInsetStyles());
  }

  _animateProgress() {
    const complete = () => {
      return this.trigger('animate:complete');
    };

    this.ui.inset.stop(true);
    this.ui.inset.velocity('stop');
    this.ui.inset.velocity(this._getInsetStyles(), {
      duration: this.ANIMATION_DURATION,
      complete
    });
  }

  _getInsetStyles() {
    return {
      width: `${ this.getValue() }%`,
      backgroundColor: this.getBarColor()
    };
  }
}

module.exports = ProgressBar;
