const {
  defaults,
  omit
} = require('underscore');
const { ItemView } = require('Marionette');

const StackedCollectionView = require('@common/components/view/stacked_collection_view/StackedCollectionView');
const ProgressBar = require('@common/components/progress/progressBar/ProgressBar');

const createProgressMarkerViewConfig = (options = {}) => {
  let {
    id,
    iconState = '',
    iconClass
  } = options;
  iconClass = iconClass ? 'icon-' + iconClass : '';

  const viewOptions = omit(options, 'id', 'iconState', 'iconClass');

  return {
    id,
    viewClass: ItemView,
    viewOptions: defaults(viewOptions, {
      className: 'progress-marker',
      attributes: {
        role: 'presentation'
      },
      template: `
        <div role="presentation" class="marker-layer background"></div>
        <div role="presentation" class="marker-layer midground ${ iconState }"></div>
        <div class="marker-layer foreground ${ iconClass }"></div>
      `
    })
  };
};

const createProgressBarViewConfig = (options = {}) => {
  const {
    id,
    labelText = '',
    lineCount = 1,
    animate = true,
    value = 0,
    sectionWidthClass = ''
  } = options;

  const progressLabelViewConfig = {
    viewClass: ItemView,
    viewOptions: {
      className: 'progress-label',
      tagName: 'p',
      template: labelText
    }
  };

  const progressBarViewConfig = {
    id,
    viewClass: ProgressBar,
    viewOptions: {
      className: 'progress-bar-wrapper',
      lineCount,
      animate,
      value
    }
  };

  return {
    viewClass: StackedCollectionView,
    viewOptions: {
      className: `progress-section ${ sectionWidthClass }`,
      viewConfigs: [progressLabelViewConfig, progressBarViewConfig]
    }
  };
};

module.exports = {
  createProgressMarkerViewConfig,
  createProgressBarViewConfig
};
