const $os = require('detectOS');
const logging = require('logging');
const _ = require('underscore');

const I18n = require('@common/libs/I18n');

const StringHelpers = require('@common/libs/helpers/types/StringHelpers');
const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');
const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');

const KeyCode = require('@common/data/enums/KeyCode');

const QuestionView = require('@training/apps/training/views/activities/pages/questions/QuestionView');

class FillInTheBlanksQuestionPage extends QuestionView {
  constructor(...args) {
    super(...args);

    this.template = _.tpl(require('@training/apps/training/templates/activities/pages/questions/FillInTheBlanksQuestionPage.html'));
    this.selectorTemplate = _.tpl(require('@training/apps/training/templates/activities/pages/questions/_fill_in_the_blanks_selector.html'));
    this.scrollBarWidth = BrowserHelpers.getScrollbarWidth();

    this.selectAnswer = this.selectAnswer.bind(this);
    this.collapseDropdown = this.collapseDropdown.bind(this);
  }

  ui() {
    return {
      selectionBox: '.selection-box',
      selectorList: '.selectorlist',
      selectorListItems: '.selectorlist li',
      blankAnswers: '.blankanswer',
      hinttext: '.hinttext',
      coach: '.bonuscharacter div',
      questionask: '.questionask',
      accessibilityNote: '#accessibility-note',
      accessibilityCorrectStr: '#accessibility-correct-note'
    };
  }

  events() {
    return {
      'click @ui.selectionBox': 'toggleDropdown',
      'keydown @ui.selectionBox': 'onKeyDownSelection',
      'click @ui.selectorListItems': 'selectAnswer',
      'blur @ui.selectorList': 'onBlur',
      'keydown @ui.selectorListItems': 'onKeyDownListItem'
    };
  }

  initialize(...args) {
    super.initialize(...args);

    // Page state, used for rerendering
    this.selectedAnswers = {};
    this.questionOptions = [];
  }

  _filterEmptyOptions(options) {
    return _.filter(options, (option) => {
      return option.optionText != null;
    });
  }

  render() {
    this.setupActionBarWithConfidence();

    this.questionOptions = this._filterEmptyOptions(this.variant.options);

    this.correctOptionCount = this.variant.correctOptionCount;
    const questionHtml = this.templatedQuestion(this.variant.questionText, this.questionOptions);

    const screenReaderStr = this.generateScreenReaderQuestion(this.variant.questionText);

    this.$el.html(this.template({
      variant: this.variant,
      questionHtml,
      questionOrdinal: this.questionOrdinal,
      screenReaderStr
    }));
    this.$('a[href^=\'http\']').attr('target', '_blank');

    return this;
  }

  viewDidAppear(...args) {
    logging.info('FillInTheBlanksQuestionPage - viewDidAppear');
    this._focusQuestionHeading();

    //TODO: #root-view & #modalview would only exist when answering questions
    //      as part of a game play-through, and when testing in Chrome & Safari,
    //      these two selectors match 0 elements (in the tests I've done, which
    //      are not exhaustive).
    //      Verify that this is actually doing something, and not just superstition.

    //Add class to parent to fix scrolling
    $('#root-view .contentwrapper, #modalview .modal-content-wrapper').addClass('matchwrap');
    super.viewDidAppear(...args);
  }

  templatedQuestion(questionText, options) {
    let newQuestionText = questionText;
    // determine max width of options
    let maxWidth = 0;
    for (const option of options) {
      const width = HTMLHelpers.getTextWidth(option.optionText);
      if (width > maxWidth) {
        maxWidth = width;
      }
    }

    // add width for the scrollbar and a 5% for ie6
    maxWidth += this.scrollBarWidth + Math.floor(maxWidth * 0.5);

    const replacementPositions = StringHelpers.getIndicesOf(newQuestionText, '[]', false).reverse();
    for (let index = 0; index < replacementPositions.length; index++) {
      const replacementPosition = replacementPositions[index];
      const selEl = this.selectorTemplate({
        maxWidth,
        ordinal: replacementPositions.length - index - 1,
        label: I18n.t('question.accessibility.fillInBlankLabel'),
        options: _.shuffle(this.questionOptions)
      });
      newQuestionText = StringHelpers.splice(newQuestionText, replacementPosition, 2, selEl);
    }

    return newQuestionText;
  }

  generateScreenReaderQuestion(questionText) {
    let newQuestionText = questionText;
    const replacementPositions = StringHelpers.getIndicesOf(questionText, '[]', false).reverse();

    for (let index = 0; index < replacementPositions.length; index++) {
      const replacementPosition = replacementPositions[index];
      newQuestionText = StringHelpers.splice(questionText, replacementPosition, 2, I18n.t('question.blank'));
    }
    newQuestionText = HTMLHelpers.stripHtmlInlineElements(newQuestionText);
    return `${ I18n.t('question.fillInTheBlank') } ${ newQuestionText }`;
  }

  setScreenReaderAnswer(answerText, correctOptions, isAnsweredCorrectly) {
    let newAnswerText = answerText;
    const replacementPositions = StringHelpers.getIndicesOf(newAnswerText, '[]', false).reverse();
    const correct = correctOptions.reverse();

    for (let index = 0; index < replacementPositions.length; index++) {
      const replacementPosition = replacementPositions[index];
      newAnswerText = StringHelpers.splice(newAnswerText, replacementPosition, 2, correct[index].optionText);
    }

    if (isAnsweredCorrectly) {
      this.ui.accessibilityCorrectStr.text(I18n.t('question.accessibility.correctAnswer')).removeClass('hidden');
    } else {
      this.ui.accessibilityCorrectStr.text(`${ I18n.t('question.accessibility.incorrectAnswer') } ${ I18n.t('question.accessibility.fillInBlankCorrectLabel') } ${ newAnswerText }`).removeClass('hidden');
    }
  }

  optionById(id) {
    return _(this.questionOptions).find((option) => {
      return option.id === id;
    });
  }

  // Animates the Answer selection action
  animateSelect(isAnsweredCorrectly) {
    this.detachEvent('click', '.selection-box');
    this.detachEvent('click', '.selectorlist li');
    this.detachEvent('blur', '.selectorList');

    const correctOptions = _.filter(this.questionOptions, (option) => {
      return option.correct;
    });
    correctOptions.sort((a, b) => {
      return a.locationOrdinal - b.locationOrdinal;
    });

    const result = [];
    for (let i = 0; i < this.ui.blankAnswers.length; i++) {
      let answerClass;
      const answerArea = this.ui.blankAnswers[i];
      if (correctOptions[i].id === this.selectedAnswers[i]) {
        answerClass = 'answered correct';
      } else {
        answerClass = 'answered incorrect';
        $(answerArea).after(`<span class='correctforincorrect'>${ correctOptions[i].optionText }</span>`);
      }
      const dpContainer = $(answerArea).find('.selection-box');
      dpContainer.addClass(answerClass);
      const option = _.find(this.questionOptions, (op) => {
        return op.id === this.selectedAnswers[i];
      });
      result.push(dpContainer.find('span').text(option.optionText));
    }

    this.setScreenReaderAnswer(this.variant.questionText, correctOptions, isAnsweredCorrectly);

    return result;
  }

  animatePoints(answeredCorrectly, points = 0) {
    const questionResult = answeredCorrectly ? 'correct' : 'incorrect';

    this.ui.hinttext.addClass(questionResult);
    this.ui.coach.removeClass('ponder').addClass(questionResult);

    const altText = answeredCorrectly ? I18n.t('coaches.correctAnswer') : I18n.t('coaches.incorrectAnswer');
    this.ui.coach.attr('aria-label', altText);

    this.showPointsArea(answeredCorrectly, points);
  }

  _allBlanksHaveAnswers() {
    return _.size(this.selectedAnswers) >= this.correctOptionCount;
  }

  selectAnswer(e) {
    // is needed so that on mobile, when you select an answer,
    // toggleDropdown doesn't get triggered after collapseDropdown
    e.stopPropagation();
    e.preventDefault();

    const $target = this.$(e.currentTarget);
    const answerString = $target.text();
    const $selectorList = $target.parents('.selectorlist');
    const $selectionBox = $selectorList.siblings('.selection-box');

    $selectorList.find('.selected').removeClass('selected')
      .removeAttr('aria-selected');
    $target.addClass('selected').attr('aria-selected', true);
    $selectionBox.text(answerString).attr('aria-label', answerString);

    const ordinal = parseInt($selectorList.attr('data-ordinal'), 10);
    const questionOptionId = parseInt($target.attr('data-id'), 10);

    if (questionOptionId >= 0) {
      this.selectedAnswers[ordinal] = questionOptionId;
    } else {
      delete this.selectedAnswers[ordinal];
    }

    if (this._allBlanksHaveAnswers()) {
      this.ui.accessibilityNote.hide();
      this.showActionBarWithConfidence();
    }

    $selectorList.removeClass('active');
    $selectionBox.trigger('focus');
  }

  onSubmit(confidenceLevel) {
    if (!this._allBlanksHaveAnswers || this.hasAnswered) {
      return;
    }
    this.hasAnswered = true;

    const optionIds = [];
    for (let i = 0; i < _.size(this.selectedAnswers); i++) {
      optionIds.push(parseInt(this.selectedAnswers[i], 10));
    }

    // Submit answer and load next activity JSON
    const activityBody = {
      questionVariantId: this.activity.get('body').question.variants[0].id,
      questionOptionIds: optionIds,
      confidenceLevel
    };

    const { hideAnswersAndReason } = this.options;
    this.activity.setAction('ANSWERQUESTION', activityBody, {
      success: (actionResponse) => {
        this.hasGotServerResponse = true;
        if (hideAnswersAndReason) {
          this.next();
        } else {
          const question = actionResponse.answer.question.variants[0];
          const options = this._filterEmptyOptions(question.options);

          for (const option of options) {
            const o = this.optionById(option.id);
            o.correct = option.correct;
            o.locationOrdinal = option.locationOrdinal;
          }

          this.createReason(question);

          if ($os.mobile) {
            $('#root-view .contentwrapper, #modalview .modal-content-wrapper').removeClass('matchwrap');
          }

          // We want the FIB selectors to be unfocusble and ignored after an answer has been submitted
          this.ui.selectionBox.attr('tabindex', -1);
          this.ui.questionask.attr('aria-hidden', true).attr('role', 'presentation');

          this.setupAndShowActionBarWithContinue();

          this.animateSelect(actionResponse.answer.isAnsweredCorrectly);
          this.animatePoints(actionResponse.answer.isAnsweredCorrectly, actionResponse.pointsEarned);

          this.scrollResultBannerIntoView(this.focusAccessibilityCorrect);

          if (actionResponse.answer.isAnsweredCorrectly) {
            if (this.gameManager != null) {
              this.gameManager.questionAnsweredCorrect();
            }
          } else if (this.gameManager != null) {
            this.gameManager.questionAnsweredIncorrect();
          }

          window.apps.auth.session.user.addPoints(actionResponse.pointsEarned);
          this.triggerAdjustment();
        }
      }
    });
  }

  onKeyDownSelection(e) {
    e.stopPropagation();
    if ((e.which === KeyCode.UP || e.which === KeyCode.DOWN) && !this.isDropdownOpen(e)) {
      this.toggleDropdown(e);
    }
  }

  onKeyDownListItem(e) {
    if (e.which === KeyCode.ESCAPE && this.isDropdownOpen(e)) {
      this.collapseDropdown();
    } else if (e.which === KeyCode.ENTER || e.which === KeyCode.SPACE) {
      this.selectAnswer(e);
    } else if (e.which === KeyCode.UP) {
      this.onUpArrow(e);
    } else if (e.which === KeyCode.DOWN) {
      this.onDownArrow(e);
    }
  }

  onUpArrow(e) {
    e.stopPropagation();
    const $selectorList = this.$(e.currentTarget).closest('.selectorlist');
    const $options = $selectorList.find('li');
    const $focussed = $selectorList.find('li.focussed');

    let selectedIndex = $options.index($focussed);

    if (selectedIndex === 0) {
      selectedIndex = $options.length;
    }

    const $nextOption = $($options.get(selectedIndex - 1));
    $focussed.removeClass('focussed');
    $nextOption.first().addClass('focussed')
      .trigger('focus');
  }

  onDownArrow(e) {
    e.stopPropagation();
    const $selectorList = this.$(e.currentTarget).closest('.selectorlist');
    const $options = $selectorList.find('li');
    const $focussed = $selectorList.find('li.focussed');

    let selectedIndex = $options.index($focussed);

    if (selectedIndex === ($options.length - 1)) {
      selectedIndex = -1;
    }

    const $nextOption = $($options.get(selectedIndex + 1));
    $focussed.removeClass('focussed');
    $nextOption.first().addClass('focussed')
      .trigger('focus');
  }

  isDropdownOpen(e) {
    const $activeDropdown = this.$('span.blankanswer.active');
    const $targetDropdown = this.$(e.currentTarget).closest('span.blankanswer');

    return $targetDropdown.is($activeDropdown);
  }

  toggleDropdown(e) {
    e.stopPropagation();
    const $blankAnswer = this.$(e.target).parents('.blankanswer');
    const $selectorList = this.$(e.target).siblings('.selectorlist');

    // Case for click again to close
    if ($blankAnswer.hasClass('active')) {
      $blankAnswer.removeClass('active');
      this.$(e.target).attr('aria-expanded', false);
      return;
    }

    this.$(e.target).attr('aria-expanded', true);

    this.ui.blankAnswers.removeClass('active');
    $blankAnswer.addClass('active');

    if ($selectorList.children('.selected').length) {
      $selectorList.children('.selected').addClass('focussed')
        .trigger('focus');
    } else {
      $selectorList.children().first()
        .addClass('focussed')
        .trigger('focus');
    }

    // If horizontal scroll occurs in dropdown, ensure the borders of every element reach the same width
    if ($selectorList.get(0).scrollWidth > $selectorList.width()) {
      this.adjustAnswerItemWidth($selectorList);
    }
  }

  adjustAnswerItemWidth($selectorList) {
    const $listItems = $selectorList.children('li');
    let maxWidth = 0;
    $listItems.each((index, el) => {
      if (el.scrollWidth > maxWidth) {
        maxWidth = el.scrollWidth;
      }
    });
    $listItems.width(maxWidth);
  }

  collapseDropdown(element = '.blankanswer.active') {
    this.$(element).children('.selection-box')
      .trigger('focus');
    this.$(element).removeClass('active');
  }

  onBlur(event) {
    _.delay((e) => {
      // Don't blur if navigating the list of options in the same parent listbox
      const $selectorList = this.$(e.target).closest('.selectorlist');
      if ($selectorList.has(this.$(document.activeElement)).length === 0 && !this.$(document.activeElement).is($selectorList)) {
        this.$(e.target).parents('.blankanswer')
          .removeClass('active');
        $selectorList.siblings('.selection-box').attr('aria-expanded', false);
      }
    }, 100, event);
    return false;
  }

  onNext() {
    if (_.isFunction(this.options.complete)) {
      this.options.complete();
    }
    return false;
  }

  _focusQuestionHeading() {
    this.ui.questionask.attr('tabindex', -1).trigger('focus');
  }

  focusAccessibilityCorrect() {
    $('#accessibility-correct-note').trigger('focus');
  }
}

module.exports = FillInTheBlanksQuestionPage;
