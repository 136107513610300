const SCORMCompletionStatusEnum = require('@common/data/enums/SCORMCompletionStatusEnum');
const _ = require('underscore');

class SCORMActivityPersistanceStrategy {
  constructor(activity, isScormComplete = false) {
    this._activity = activity;
    this._isScormComplete = isScormComplete;
  }

  saveProgress(body, callback = () => {}) {
    // Making sure once SCORM was completed (scoStatus: 1), it cannot go back to notCompleted (scoStatus: 0)
    if (this._isScormComplete) {
      body.scoStatus = SCORMCompletionStatusEnum.completed;
    }
    this._isScormComplete = body.scoStatus === SCORMCompletionStatusEnum.completed;

    // CI-2706 - If cmi.core.lesson_location is a number, it should be converted to a string
    // This is because the SCORM API expects a string for cmi.core.lesson_location
    if (_.isArray(body?.scoValues)) {
      const lessonLocation = _.findWhere(body.scoValues, { scoKey: 'cmi.core.lesson_location' });
      if (lessonLocation && _.isNumber(lessonLocation.scoValue) && !_.isNaN(lessonLocation.scoValue)) {
        lessonLocation.scoValue = lessonLocation.scoValue.toString();
      }
    }

    return this._activity.setAction('SCORMUPDATE', body, {
      showSpinner: false,
      success(res) {
        callback(res.lastModify);
      }
    });
  }

  canSaveProgress() {
    return this._activity.isUnderway();
  }
}

module.exports = SCORMActivityPersistanceStrategy;
