const _ = require('underscore');
const Backbone = require('Backbone');

class TasksNotifierModel extends Backbone.Model {

  apiEndpoint() {
    return '/tms/taskInstances/taskUnread';
  }

  parse(resp = {}) {
    const response = (typeof resp === 'string') ? JSON.parse(resp) : resp;
    if (_.isEmpty(response)) {
      return {
        urgentTaskCount: 0,
        hasUpdates: false
      };
    }
    const hasUpdates = response?.urgentTaskCount > 0;

    return {
      ...response,
      hasUpdates
    };
  }

  fetch(options = {}) {
    const opts = _.extend({
      skipGlobalHandler: true
    }, options);
    return super.fetch(opts);
  }

  sync(method, model, options = {}) {
    options.error = _.wrap(options.error, (originalOptionError = () => {}, xhr, status, error, ...args) => {
      xhr.skipGlobalHandler = true;
      originalOptionError(xhr, status, error, ...args);
    });

    return super.sync(method, model, options);
  }

}

module.exports = TasksNotifierModel;
