import { joinClassNames } from '@common/libs/helpers/app/HTMLHelpers';
import I18n from '@common/libs/I18n';
import {
  AxIconButton,
  AxTooltip,
  type AxIconButtonProps,
  type AxTooltipProps,
  type IconNames
} from '@common/modules/react/themes/components';
import { ClickAwayListener } from '@mui/material';
import {
  useEffect,
  useRef,
  useState
} from 'react';


export type TooltipButtonProps = {
  tooltipProps: MakeKeyOptional<AxTooltipProps, 'children'>,
  buttonProps?: AxIconButtonProps & {
    /**
     * The name of the icomoon class icon to display.
     */
    iconName?: IconNames;
    /**
     * Override or extend the styles applied to the component.
     * @deprecated Use the `iconName` prop for icon selection. In case your icon name is wrapped in a CSS class, you can pass it here.
     */
    className?: string;
  }
};
/**
 * The `TooltipButton` component shows a tooltip when you click it.
 * Unlike the `AxTooltip` component, it functions as a button with a tooltip.
 *
 * **💡 Note**: This component uses brand colors, specifically the link color.
 *
 * ### Links
 * - • [Mockups](https://www.figma.com/design/mAGiAo8UwCUfdpW9SNdswW/%F0%9F%A7%B0-UIKit?node-id=11166-5230&t=ua80nvMEqT6jvBaY-11)
 */
export default function TooltipButton(props: TooltipButtonProps) {
  const {
    tooltipProps,
    buttonProps = {}
  } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (buttonRef.current) {
      const parent = buttonRef.current.closest('fieldset');
      if (parent?.getAttribute('aria-hidden') === 'true') {
        buttonRef.current.tabIndex = -1;
      }
    }
  }, []);

  const [open, setOpen] = useState(false);

  const handleIconButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    buttonProps?.onClick?.(event);
    buttonRef.current?.focus();
    setOpen(!open);
  };

  const handleTooltipClose = () => {
    tooltipProps?.onClose?.(new Event('click'));
    setOpen(false);
  };

  const {
    className = '',
    iconName = 'icon-question_sign',
    sx,
    ...otherButtonProps
  } = buttonProps;

  const iconClass = joinClassNames(className, iconName);

  return (
    <ClickAwayListener onClickAway={ handleTooltipClose } mouseEvent='onMouseDown'>
      <AxTooltip
        aria-label={ I18n.t('accessibility.tooltipInstructions') }
        placement='top'
        { ...tooltipProps }
        open={ open }
        onClose={ handleTooltipClose }
        //These are important to disable to preserve the click handler effects
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <AxIconButton
          size='medium'
          ref = { buttonRef }
          variant='ghost-link-branded'
          className={ iconClass }
          sx={{
            fontSize: 'var(--ax-tooltip-button-size)',
            ...sx
          }}
          { ...otherButtonProps }
          onClick={ handleIconButtonClick }
        />
      </AxTooltip>
    </ClickAwayListener>
  );
}
