const {
  defaults,
  extend,
  result
} = require('underscore');

require('@common/modules/main/menu/item/selectable/SelectableBehavior');

const MenuItemView = require('@common/modules/main/menu/item/MenuItemView');
const MenuAppItemView = require('@common/modules/main/menu/item/app/MenuAppItemView');
const SelectableMenuItemView = require('@common/modules/main/menu/item/selectable/SelectableMenuItemView');
const TimelineSelectableNotifiableMenuItemView = require('@common/modules/main/menu/item/selectable/TimelineSelectableNotifiableMenuItemView');
const TasksSelectableNotifiableMenuItemView = require('@common/modules/main/menu/item/selectable/TasksSelectableNotifiableMenuItemView');


module.exports = {
  createSelectableNotifiableItemViewDefinition: (viewDefinition = {}) => {
    return extend({}, viewDefinition, {
      ViewClass: TimelineSelectableNotifiableMenuItemView,
      timelineNotifier: window.app.sessionController.timelineNotifier,
      behaviors: extend({
        SelectableBehavior: {
          selectableId: viewDefinition.configId
        }
      }, result(viewDefinition, 'behaviors') )
    });
  },
  createSelectableTaskNotifiableItemViewDefinition: (viewDefinition = {}) => {
    return extend({}, viewDefinition, {
      ViewClass: TasksSelectableNotifiableMenuItemView,
      tasksNotifier: window.app.sessionController.tasksNotifier,
      behaviors: extend({
        SelectableBehavior: {
          selectableId: viewDefinition.configId
        }
      }, result(viewDefinition, 'behaviors') )
    });
  },

  createSelectableItemViewDefinition: (viewDefinition = {}) => {
    return extend({}, viewDefinition, {
      ViewClass: SelectableMenuItemView,
      behaviors: extend({
        SelectableBehavior: {
          selectableId: viewDefinition.configId
        }
      }, result(viewDefinition, 'behaviors') )
    });
  },

  createAppItemViewDefinition: (viewDefinition = {}) => {
    return defaults(viewDefinition, { ViewClass: MenuAppItemView });
  },

  createMenuItemViewDefinition: (viewDefinition = {}) => {
    return extend({}, viewDefinition, {
      ViewClass: MenuItemView,
      linkClass: 'menu-item clearfix selectable clickable'
    });
  }
};
