const I18n = require('@common/libs/I18n');
const { VIDEO_MODULE } = require('@common/data/enums/ActivityType');

const ProgressMarkerOptions = require('@training/apps/training/assessmentProgress/ProgressMarkerOptions');
const AbstractProgressConfig = require('@training/apps/training/assessmentProgress/AbstractProgressConfig');

const getModuleActivity = (assessment) => {
  return assessment.activities.find((activity) => {
    return activity.isTrainingContentActivity();
  });
};

const hasMixedModuleTypes = (assessment) => {
  const {
    hasVideos,
    hasModules
  } = assessment.activities.filter((activity) => {
    return activity.isTrainingContentActivity();
  }).reduce((memo, activity) => {
    const isVideo = activity.getType() === VIDEO_MODULE;

    memo.hasVideos |= isVideo;
    memo.hasModules |= !isVideo;

    return memo;
  }, {
    hasVideos: false,
    hasModules: false
  });

  return hasVideos && hasModules;
};

class AssessmentModuleProgressConfig extends AbstractProgressConfig {

  static ProgressBarConfigId = 'module-progress-bar';

  static MarkerConfigId = 'module-marker';

  constructor(assessment) {
    super();

    this.assessment = assessment;
  }

  isValid() {
    return this.assessment.activities.getTotalModulesActivityCount() > 0;
  }

  getSegmentLabelText() {
    const totalModuleCount = this.assessment.activities.getTotalModulesActivityCount();
    const trainingModuleActivity = getModuleActivity(this.assessment);
    const mixedModules = totalModuleCount > 1 && hasMixedModuleTypes(this.assessment);
    const showModuleText = mixedModules || trainingModuleActivity.getType() !== VIDEO_MODULE;

    if (showModuleText) {
      if (totalModuleCount === 1) {
        return I18n.t('assessments.progress.segment.module.singular');
      }
      return I18n.t('assessments.progress.segment.module.plural', { count: totalModuleCount });

    }
    if (totalModuleCount === 1) {
      return I18n.t('assessments.progress.segment.video.singular');
    }
    return I18n.t('assessments.progress.segment.video.plural', { count: totalModuleCount });


  }

  getSegmentValue() {
    const totalModuleCount = this.assessment.activities.getTotalModulesActivityCount();
    let progressValue = this.assessment.activities.getClosedModulesActivityCount();
    // If the assessment is part of an extra training session and the next activity can be skipped,
    // then we treat it as completed for the purposes of the progress bar
    if (this.assessment.isExtraTraining() && this._nextActiveModuleActivity()?.isBypassable()) {
      progressValue++;
    }
    return progressValue / totalModuleCount * 100;
  }

  getProgressBarOptions() {
    return {
      id: AssessmentModuleProgressConfig.ProgressBarConfigId,
      labelText: this.getSegmentLabelText(),
      lineCount: this.assessment.activities.getTotalModulesActivityCount(),
      value: this.getSegmentValue()
    };
  }

  _nextActiveModuleActivity() {
    const nextActivity = this.assessment.activities.nextActivity();
    if (nextActivity && nextActivity.isTrainingContentActivity()) {
      return nextActivity;
    }
    return null;
  }

  getMarkerOptions() {
    if (getModuleActivity(this.assessment).getType() === VIDEO_MODULE) {
      return ProgressMarkerOptions.Video;
    }
    return ProgressMarkerOptions.Module;
  }


  containsActiveActivity() {
    // If this config is not valid, then it can never contain an active activity
    if (!this.isValid()) {
      return false;
    }
    // If the active activity is a module, then this config is active
    return Boolean(this._nextActiveModuleActivity());
  }
}

module.exports = AssessmentModuleProgressConfig;
