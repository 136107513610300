const env = require('env');
const $os = require('detectOS');
const $ = require('jquery');
const Marionette = require('Marionette');
const PDFObject = require('pdfobject');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

const I18n = require('@common/libs/I18n');
const LocalStorageHelpers = require('@common/libs/helpers/app/LocalStorageHelpers');
const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');
const PdfViewerHelpers = require('@common/libs/helpers/app/PdfViewerHelpers');
const { isMsTeamsIntegration } = require('@common/libs/helpers/app/NativeBridgeHelpers');
const ExternalWindowService = require('@common/services/externalWindow/ExternalWindowService');

require('@common/libs/behaviors/resizable/Resizable');
require('@common/components/mediaFilePreview/views/PdfContentShow.less');

class PdfContentShowView extends Marionette.ItemView {
  className() {
    return 'document-wrapper parent-height';
  }

  ui() {
    return {container: '#pdf-container'};
  }

  events() {
    return {
      'click button.open-pdf': 'openPdf'
    };
  }

  behaviors() {
    return {Resizable: {}};
  }

  getTemplate() {
    return '<div id="pdf-container"></div>';
  }

  constructor(options = {}) {
    super(options);

    // shouldShowPdfjsPrint is set false for mobile devices.
    // forceShowPdfjsDownload is set true for certificates, so the download button is shown and this overrides
    // the tenant property for learnerZoneAllowPdfDownload
    ({
      shouldShowPdfjsPrint: this.shouldShowPdfjsPrint = false,
      forceShowPdfjsDownload: this.forceShowPdfjsDownload = false
    } = options);

    this.pdfJsSupported = LocalStorageHelpers.supportsLocalStorage();
  }

  onRender() {
    const { path } = this.model.get('originalFile') || this.model.get('file');
    let learnerZoneAllowPdfDownload = true;

    try {
      learnerZoneAllowPdfDownload = TenantPropertyProvider.get().getProperty('learnerZoneAllowPdfDownload');
    } catch (e) {
      // do nothing
    }

    const forcePDFJS = () => {
      if ($os.browser === 'firefox') {
        // firefox should always use PDF.js, because its internal PDF viewer sucks
        return true;
      }
      if (isMsTeamsIntegration()) {
        // MSTeams uses PDF.js
        return true;
      }
      if (!learnerZoneAllowPdfDownload && !this.forceShowPdfjsDownload) {
        // if users are not allowed to download PDFs, we want to use PDF.js because then we have control over
        // hiding the download button. If the download is forced (as in the case of Certificates), we can
        // use the browser's default PDF viewer since it always shows a download button.
        return true;
      }
      // in any other case we are ok with using the browser's default PDF viewer
      return false;
    }

    const forceIframe = () => {
      if (isMsTeamsIntegration()) {
        return false; // msTeams doesn't want iframes
      }
      return true;
    };


    if ($os.ios) {
      this.ui.container.append(`\
        <div class="open-pdf-button-container">
          <button class="open-pdf ax-button ax-button--branded">${ I18n.t('pdf.openPdf') }</button>
        </div>\
      `);

      this.PDFExternalWindowService = new ExternalWindowService({
        windowOptions: {
          href: path,
          windowId: 'Axonify_External_PDF'
        },
        popupBlockedHandler: (message) => {
          // Currently only supporting popup blacker error message in training
          window?.app?.layout?.flash?.error?.(message);
        }
      });
    } else {
      const iframe = PDFObject.embed(path, this.ui.container, {
        id: 'myPdf',
        width: '100%',
        height: '100%',
        assumptionMode: false,
        forcePDFJS: forcePDFJS(),
        forceIframe: forceIframe(),
        fallbackLink: !this.pdfJsSupported ? I18n.t('pdf.noViewer') : false,
        supportRedirect: true,
        suppressConsole: true,
        PDFJS_URL: `${ env.settings.version }/common/vendor/pdfjslib/web/viewer.html`,
        pdfOpenParams: {
          navpanes: 1,
          view: 'FitH',
          pagemode: $os.mobile ? 'none' : 'thumbs'
        }
      });

      if (!isMsTeamsIntegration()) {
        PdfViewerHelpers.addPinchToZoomListeners(iframe);
        if (!this.shouldShowPdfjsPrint) {
          PdfViewerHelpers.hidePrintButton(iframe);
        }
      }

      // forceShowPdfjsDownload is used in the case where a download is forced (as in the case of Certificates)
      // if the download is not forced, we want to hide the download button if the tenant property is set to false
      if (!this.forceShowPdfjsDownload && !learnerZoneAllowPdfDownload) {
        PdfViewerHelpers.hideDownloadButton(iframe);
        PdfViewerHelpers.hidePrintButton(iframe);
      }

      // To prevent draging of clickable elements
      BrowserHelpers.preventDragAndDrop($(iframe));
    }
  }

  openPdf() {
    this.PDFExternalWindowService.openWindow();
  }

  onResize() {
    const width = this.$el.innerWidth();
    const height = this.$el.parent().height();

    this.ui.container.css({
      width,
      height
    }).attr({
      width,
      height
    });
  }

  onDestroy() {
    if (this.PDFExternalWindowService != null) {
      this.PDFExternalWindowService.closeWindow();
    }
  }
}

module.exports = PdfContentShowView;
