const _ = require('underscore');
const I18n = require('@common/libs/I18n');

const AssessmentType = require('@common/data/enums/AssessmentType');

const AssessmentTypeIconFormatter = require('@training/apps/training/assessmentTitle/AssessmentTypeIconFormatter');

const AssessmentTopicItemView = require('@training/apps/training/views/assessments/AssessmentTopicItemView');

class AssessmentExamItemView extends AssessmentTopicItemView {
  template = _.tpl(require('@training/apps/training/templates/assessments/AssessmentExamItemView.html'));

  minimize = () => {};

  _renderDueDateString = () => {};

  _getResultClass(lastRelevantResult) {
    if (lastRelevantResult.completed) {
      return super._getResultClass(lastRelevantResult);
    }
    return 'incomplete';

  }

  _renderAriaLabel(resultClass) {
    const name = this.model.get('program').name;
    super._renderAriaLabel(resultClass, name);
  }

  _renderGrade(lastRelevantResult, resultClass) {
    const grade = lastRelevantResult.score != null ? lastRelevantResult.score : Math.round((lastRelevantResult.correctAnswerCount / Math.max(1, lastRelevantResult.totalAnswerCount)) * 100);
    this.$('.grade-value').text(I18n.t('assessments.item.grade.value', {grade}));

    if (lastRelevantResult.completed) {
      this.$('.grade-text').text(I18n.t(`assessments.item.grade.text.${ resultClass }`));
    } else {
      this.$('.grade-text').text(I18n.t('assessments.item.grade.text.currentScore'));
    }
  }

  _renderIcon() {
    const {
      iconClass,
      iconLabel
    } = AssessmentTypeIconFormatter(AssessmentType.FormalExamTraining);

    this.$('.topic .icon')
      .addClass(iconClass)
      .removeClass('hidden')
      .attr('aria-label', iconLabel);
  }

  _renderContentString() {
    const lastRelevantResult = this.model.get('lastRelevantResult');
    const attemptCount = this.model.get('attemptsRemaining') || 0;

    const $contentStringDiv = this.$('.tile-meta .content');
    const $attemptsStringDiv = this.$('.tile-meta .attempts');

    const contentString = (() => {
      if ((lastRelevantResult == null)) {
        const questionCount = this.model.get('questionCount');

        if (questionCount === 1) {
          return I18n.t('assessments.item.content.questions.singular');
        } else if (questionCount > 1) {
          return I18n.t('assessments.item.content.questions.plural', {count: questionCount});
        }

      } else if (lastRelevantResult.completed) {
        const lastScore = {
          correct: lastRelevantResult.correctAnswerCount,
          total: lastRelevantResult.totalQuestionCount
        };
        return I18n.t('assessments.item.lastScore', lastScore);
      } else {
        const progress = {
          answered: lastRelevantResult.totalAnswerCount,
          total: lastRelevantResult.totalQuestionCount
        };
        return I18n.t('assessments.item.progress', progress);
      }

      return undefined;
    })();

    $contentStringDiv.text(contentString);

    let attemptsString = '';

    if (attemptCount === 1) {
      attemptsString = I18n.t('assessments.item.attemptsRemaining.singular');
    } else {
      attemptsString = I18n.t('assessments.item.attemptsRemaining.plural', { count: attemptCount });
    }

    if (lastRelevantResult != null && lastRelevantResult.passed) {
      $attemptsStringDiv.hide();
    } else {
      $attemptsStringDiv.text(attemptsString);
    }
  }

  _renderRewardPointsString() {
    const lastRelevantResult = this.model.get('lastRelevantResult');
    const total = this.model.get('totalPointsEarnable');

    let points = 0;

    if (lastRelevantResult != null && lastRelevantResult.pointsEarned != null) {
      points = lastRelevantResult.pointsEarned;
    }

    const $rewardStringDiv = this.$('.tile-meta .rewards');

    if (lastRelevantResult != null && !lastRelevantResult.passed) {
      $rewardStringDiv.text(I18n.t('assessments.item.passGrade', { grade: this.passingGrade }));
    } else if (total === 0) {
      $rewardStringDiv.hide();
    } else if (lastRelevantResult == null) {
      $rewardStringDiv.text(I18n.t('assessments.item.rewardPoints.totalPointsEarnable', { total }));
    } else {
      $rewardStringDiv.text(I18n.t('assessments.item.rewardPoints.earnedSoFar', {
        points,
        total
      }));
    }

    return undefined;
  }
}

module.exports = AssessmentExamItemView;
