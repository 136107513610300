const GameHelper = require('@common/libs/helpers/features/GameHelper');
const {GameList} = require('./GameList');

class GameScoresList extends GameList {
  apiEndpoint() {
    return '/games/available';
  }

  comparator(model) {
    return model.get('name');
  }

  parse(response = {}) {
    const items = response.games || response;
    return items.filter((game) => {
      return GameHelper.isSupportedGameType(game.type);
    });
  }

  getGamesWithScoring() {
    return this.fetch({
      data: {
        withScoring: true
      }
    });
  }
}

module.exports = GameScoresList;
