const _ = require('underscore');
const logging = require('logging');

const UIKit = require('@training/widgets/UIKit');
const I18n = require('@common/libs/I18n');

const AssessmentType = require('@common/data/enums/AssessmentType');
const AssessmentItemViewFactory = require('@training/apps/training/views/assessments/AssessmentItemViewFactory');
const AssessmentCompletionAction = require('@training/apps/training/views/assessments/AssessmentCompletionAction');
const AssessmentLaunchContext = require('@common/data/enums/AssessmentLaunchContext');
const MilestoneItemView = require('@training/apps/training/views/assessments/MilestoneItemView');

const ActionBarButtonFactory = require('@common/components/actionBarButton/ActionBarButtonFactory');
const ActionBarType = require('@common/components/actionBarButton/ActionBarType');

class ObjectiveResultPage extends UIKit.View {

  constructor(options) {
    super(options);

    ({
      doOnDone: this.doOnDone
    } = options);

  }

  get template() {
    return _.tpl(require('@training/apps/training/templates/assessments/ObjectiveResultPage.html'));
  }

  className() {
    return 'result-page';
  }

  render() {
    this.assessmentType = this.model.get('assessmentType');

    this.$el.html(this.template({
      type: this.assessmentType
    }));

    this._renderTitle();
    this._renderCoach();
    this._renderResultItem();
    this._renderUpcomingItem();
    this._renderMessage();
    this._renderButtons();
    this._wasClicked = false;

    return this;
  }

  viewDidAppear() {
    this.$('.current-result-container').trigger('focus');
  }

  _renderTitle() {
    if (this.assessmentType === 'ExtraTrainingQuestions') {
      window.app.layout.setTitle(I18n.t(`assessments.select.header.title.learnerTraining`));
    } else {
      window.app.layout.setTitle(I18n.t(`assessments.select.header.title.${ this.assessmentType }`));
    }
  }

  _renderCoach() {
    const coachClass = this._getCoachClass();
    const hasNoCoach = this.$('.bonuscharacter div').hasClass('NoCoach');

    if (hasNoCoach) {
      this.$('.bonuscharacter div').addClass(coachClass)
        .attr('role', 'presentation');
    } else if (coachClass === 'correct') {
      this.$('.bonuscharacter div').addClass(coachClass)
        .attr('role', 'img');
    } else if (coachClass === 'incorrect') {
      this.$('.bonuscharacter div').addClass(coachClass)
        .attr('role', 'img');
    }
  }

  _getCoachClass() {
    if (this.model.get('hasAttempt')) {
      if (this.model.get('hasPassedLastAttempt')) {
        return 'correct';
      }
      return 'incorrect';

    }

    return '';
  }

  _renderResultItem() {
    const resultItem = this.getOption('resultItem');
    const resultItemButtonOptions = this._getResultItemButtonOptions();
    const itemView = AssessmentItemViewFactory.create(this.assessmentType, {
      model: resultItem,
      showIcon: true,
      ...resultItemButtonOptions
    });
    this.setSubviewIn(itemView, {
      regionSelector: '.current-result-container .result-container',
      transition: UIKit.View.Transitions.NONE
    });
  }

  _renderUpcomingItem() {
    const upcomingItem = this.getOption('upcomingItem');
    const isScheduledExam = this._isScheduledExamAssessment();

    if (this.isRequiringRetake()) {
      this.$('.next-result-container').addClass('hidden');

    } else if (upcomingItem?.get('milestone')) {
      const itemView = new MilestoneItemView({
        model: upcomingItem
      });

      this.$('.next-result-container > h2').addClass('hidden');

      this.setSubviewIn(itemView, {
        regionSelector: '.next-result-container .result-container',
        transition: UIKit.View.Transitions.NONE
      });

    } else if (upcomingItem && !isScheduledExam) {
      const upcomingItemButtonOptions = this._getUpcomingItemButtonOptions();
      const itemView = AssessmentItemViewFactory.create(upcomingItem.getForAssessmentType(), {
        model: upcomingItem,
        showIcon: true,
        ...upcomingItemButtonOptions
      });
      this.setSubviewIn(itemView, {
        regionSelector: '.next-result-container .result-container',
        transition: UIKit.View.Transitions.NONE
      });

    } else {
      this.$('.next-result-container').addClass('hidden');
    }
  }

  _renderMessage() {
    const isComplete = this.model.get('isComplete');

    this.$('.result-message').toggleClass('hidden', isComplete);

    if (!isComplete && (this.assessmentType === AssessmentType.FormalExamTraining)) {
      this.$('.result-message .center').text(I18n.t('assessments.result.message.incomplete'));
    }
  }

  getBackToListButton() {
    const viewSessionTrainingListStringId = `assessments.select.viewPage.${ this.getOption('sessionTrainingType') }`;
    const buttonText = (() => {
      if (I18n.hasString(viewSessionTrainingListStringId)) {
        return I18n.t(viewSessionTrainingListStringId);
      }
      logging.error(`'View {page}' string not found for sessionTrainingType '${ this.getOption('sessionTrainingType') }', using fallback.`);
      return I18n.t('assessments.select.back');

    })();
    const button = ActionBarButtonFactory.createButtonWithCustomText(
      buttonText,
      this.executeCompletionWith.bind(this, AssessmentCompletionAction.YieldControl)
    );

    return ActionBarButtonFactory.toggleButtonSecondary(button, true);
  }

  getDoneButton() {
    const doneAction = _.isFunction(this.doOnDone) ? AssessmentCompletionAction.Done : AssessmentCompletionAction.YieldControl;
    return ActionBarButtonFactory.createButtonType(
      ActionBarType.COMPLETED_TRAINING,
      this.executeCompletionWith.bind(this, doneAction)
    );
  }

  _isSelfDirectedAssessment() {
    const launchContext = this.model.get('launchContext');
    return launchContext === AssessmentLaunchContext.TOPIC_SEARCH
      || launchContext === AssessmentLaunchContext.SELF_DIRECTED
      || launchContext === AssessmentLaunchContext.TOPIC_DETAILS;
  }

  _isScheduledExamAssessment() {
    const launchContext = this.model.get('launchContext');
    return launchContext === AssessmentLaunchContext.EXAM;
  }

  _renderButtons() {
    const buttons = [];
    const isRequiringRetake = this.isRequiringRetake();
    const isSelfDirected = this._isSelfDirectedAssessment();
    const upcomingItem = this.getOption('upcomingItem');

    if (
      this.getOption('canGoBackAfterCompletion') // Only show a list if there's a list to go back to; but...
      && !((upcomingItem?.get('milestone') === true) && !isRequiringRetake) // don't show the list button if the user reached a milestone
    ) {
      buttons.unshift(this.getBackToListButton());
    } else if (!isRequiringRetake || isSelfDirected) {
      // "Done" can only appear in the case where a retake isn't required but the user can't go back to a list,
      // this logic may be flawed for the "Headless" initiator case or maybe just redundant.
      // It will also appear if the 'upcoming' item is a milestone that has been reached
      buttons.unshift(this.getDoneButton());
    }

    this.actionBar.setActionButton(buttons);
  }

  _getResultItemButtonOptions() {
    let buttonOptions = {};
    const isRequiringRetake = this.isRequiringRetake();

    if (isRequiringRetake) {
      buttonOptions = {
        isRetake: true,
        retakeCallback: this.executeCompletionWith.bind(this, AssessmentCompletionAction.Retake)
      };
    }

    return buttonOptions;
  }

  _getUpcomingItemButtonOptions() {
    let buttonOptions = {};
    const isScheduledExam = this._isScheduledExamAssessment();
    const upcomingItem = this.getOption('upcomingItem');

    if (upcomingItem && !isScheduledExam && !upcomingItem.get('milestone')) {
      buttonOptions = {
        showStartNext: true,
        startNextCallback: this.executeCompletionWith.bind(this, AssessmentCompletionAction.StartNext)
      };
    }

    return buttonOptions;
  }

  executeCompletionWith(action) {
    let completeCallback = this.getOption('complete');
    if (action === AssessmentCompletionAction.Done && _.isFunction(this.doOnDone)) {
      completeCallback = this.doOnDone;
    }
    if (this._wasClicked === false && _.isFunction(completeCallback)) {
      completeCallback(action);
    }
    this._wasClicked = true;
  }

  isRequiringRetake() {
    return this.model.get('isRetake');
  }

  onClose() {
    this.actionBar.setActionButton();
  }
}

module.exports = ObjectiveResultPage;
