const I18n = require('@common/libs/I18n');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const ReportDataList = require('@training/apps/search/reports/domain/ReportDataList');
const ReportsPageLayoutView = require('@training/apps/search/reports/pages/ReportsPageLayoutView');

class ReportsPageLayoutController extends LayoutController {
  initialize(options = {}) {
    ({
      pageTitle: this.pageTitle,
      tableTitle: this.tableTitle,
      tableCount: this.tableCount,
      collectionClass: this.collectionClass,
      reportType: this.reportType,
      hasResult: this.hasResult,
      linkToPage: this.linkToPage,
      searchPageState: this.searchPageState
    } = options);

    this.viewDefinition = this.viewDefinition.bind(this);
    this.filterContext = this.searchPageState.get('filterContext');
    const communityId = this.filterContext
      && this.filterContext.get('community')
      && this.filterContext.get('community').get('id');

    if (communityId != null) {
      this.param = { communityId };
    } else {
      this.param = {};
    }

    if (this.hasResult !== null) {
      this.param.hasResult = false;
    }

    this.pagesCollection = new ReportDataList([], {
      reportType: this.reportType,
      additionalOptions: this.param
    });

    this.pagesCollection.getFirstPage({ error: this._onUpdateTableFail});
  }

  viewDefinition() {
    return {
      ViewClass: ReportsPageLayoutView,
      pageTitle: this.pageTitle,
      tableTitle: this.tableTitle,
      tableCount: this.tableCount,
      collection: this.pagesCollection
    };
  }

  _updateTable(filterContext, ViewClass) {
    if (filterContext.get('community') != null) {
      this.param.communityId = filterContext.get('community').get('id');
    } else if (filterContext.get('timePeriodDate') !== -1) {
      this.param.timePeriodDate = filterContext.get('timePeriodDate').toString();
      this.param.timePeriod = 'month';
    } else {
      delete this.param.timePeriodDate;
      delete this.param.timePeriod;
    }

    this.pagesCollection.reset();
    this.pagesCollection.state.currentPage = 0;

    this.pagesCollection.getFirstPage({ error: this._onUpdateTableFail});

    this.listenTo(this.pagesCollection, 'sync', () => {
      this.viewClass = new ViewClass({
        collection: this.pagesCollection,
        linkToPage: this.linkToPage,
        tableTitle: this.tableTitle,
        tableCount: this.tableCount
      });
      this.getView().tableRegion.show(this.viewClass);
    });
  }

  _onUpdateTableFail(collection, xhr) {
    const exception = AxonifyExceptionFactory.fromResponse(xhr);
    if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED) {
      xhr.skipGlobalHandler = true;
      window.app.layout.flash.error(I18n.t('discover.pageAccess.community.error.3017'));
    }
  }

  regionControllers() {
    return {
      tableRegion: {
        viewDefinition: {
          ViewClass: this.collectionClass,
          collection: this.pagesCollection,
          linkToPage: this.linkToPage,
          tableTitle: this.tableTitle,
          tableCount: this.tableCount
        }
      }
    };
  }
}

module.exports = ReportsPageLayoutController;

