const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');
const logging = require('logging');
const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('@common/services/error/AxonifyExceptionCode');

const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const IntegrationController = require('@training/apps/search/communitiesManagement/integration/IntegrationController');

const CommunityManagementLayout = require('@training/apps/search/communitiesManagement/CommunityManagementLayout');
const CommunitySettingsModel = require('@training/apps/search/communitiesManagement/models/CommunitySettingsModel');

const GeneralSettingsTabView = require('@training/apps/search/communitiesManagement/generalSettings/GeneralSettingsTabView');
const AccessSettingsTabView = require('@training/apps/search/communitiesManagement/accessSettings/AccessSettingsTabView');
const ContentSettingsLayout = require('@training/apps/search/communitiesManagement/contentSettings/ContentSettingsLayout');

const CommunityManagementTabsEnum = require('@training/apps/training/enums/CommunityManagementTabsEnum');

const Community = require('@common/data/models/Community');
const TeamsList = require('@training/apps/search/communitiesManagement/collections/TeamsList');
const GroupList = require('@training/apps/search/communitiesManagement/GroupList');
const CommunityContext = require('@common/data/models/CommunityContext');
const CommunityManagementTabController = require('@training/apps/search/communitiesManagement/CommunityManagementTabController');
const CommunityHeaderSimpleController = require('@training/apps/search/CommunityHeaderSimpleController');

class CommunityManagementController extends LayoutController {
  initialize(options = {}) {
    ({
      communityId: this.communityId
    } = options);

    this._onCommunityError = this._onCommunityError.bind(this);

    this.usersLanguage = window.apps.auth.session.user.get('language');
    this.groupList = new GroupList();

    this.context = new CommunityContext({
      languageFormatCode: this.usersLanguage,
      groupList: this.groupList
    });

    this.groupList.fetch();

    this.teamsList = new TeamsList();
    this.teamsList.fetch();


    if ( this.communityId) {
      this.community = new Community({ id: this.communityId });

      this.community.fetch({
        success: () => {
          this.context.set('community', this.community);
        },
        error: (model, xhr) => {
          this._onCommunityError(xhr);
        }
      });
    }

    this.isCommsEnabled = TenantPropertyProvider.get().getProperty('communicationsEnabled');

    this.model = new CommunitySettingsModel({}, {
      id: this.communityId,
      isCommsEnabled: this.isCommsEnabled
    });

    if (this.communityId) {
      this.model.fetch().fail(this._onCommunityError);
    }
  }

  viewDefinition() {
    return {
      ViewClass: CommunityManagementLayout
    };
  }

  regionControllers() {
    return {
      headerRegion: this.communityId ? {
        ViewControllerClass: CommunityHeaderSimpleController,
        community: this.model
      } : {},
      tabsRegion: {
        ViewControllerClass: CommunityManagementTabController,
        tabConfigs: this._getTabConfigs()
      },
      actionBarRegion: {
        delegateEvents: {
          'view:show': () => {
            const buttons = [
              {
                type: 'customText',
                text: I18n.t('general.save'),
                onClick: this._save.bind(this)
              },
              {
                type: 'customText',
                text: I18n.t('general.cancel'),
                className: 'white',
                onClick: this._navigateBack.bind(this)
              }
            ];

            window.app.layout.actionBar.setActionButton(buttons);
          }
        }
      }
    };
  }

  _onCommunityError(xhr) {
    window.app.layout.hideSpinner();
    let error;
    let shouldNavigate = false;

    // There is a rare case where the EntityPoller will fail (for whatever reason) and the error handler is triggered
    // with a string argument instead of the xhr object.
    if (typeof xhr === 'string') {
      error = xhr;
    } else {
      xhr.skipGlobalHandler = true;

      const exception = AxonifyExceptionFactory.fromResponse(xhr);
      const errorCode = exception.getErrorCode();
      logging.error(exception.getErrorMessage());

      let cleanedResponse = null;

      switch (errorCode) {
        case AxonifyExceptionCode.CLIENT_ERROR_VALUE_OUT_OF_BOUNDS:
          error = I18n.t('photoCrop.cropError');
          break;
        case AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED:
          error = I18n.t('discover.pageAccess.community.error.3017');
          shouldNavigate = true;
          break;
        case AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY:
          error = I18n.t('discover.pageAccess.community.error.3001');
          shouldNavigate = true;
          break;
        case AxonifyExceptionCode.CONTRACT_ERROR_FEATURE_UNAVAILABLE:
          error = I18n.t('discover.access.error.2012');
          shouldNavigate = true;
          break;
        case AxonifyExceptionCode.CLIENT_ERROR_UNSAFE_CONTENT:
          cleanedResponse = JSON.parse(xhr.responseJSON.cleanedBody);
          if (HTMLHelpers.htmlDecode(cleanedResponse.code) !== this.model.get('code').trim()) {
            error = I18n.t('communitiesManagement.error.3064.code');
          } else {
            error = I18n.t('discover.access.error.3064');
          }
          break;
        case AxonifyExceptionCode.CLIENT_ERROR_EXTERNAL_CODE_EXISTS:
          // While editing, there will be an original community code which we want to reset the code to if they've chosen
          // one that's already in use.
          if (this.originalCommunityCode) {
            error = I18n.t('communitiesManagement.error.edit.3054', { code: this.model.get('code')});
            this.model.set('code', this.originalCommunityCode);
          } else {
            error = I18n.t('communitiesManagement.error.3054', { code: this.model.get('code')});
          }
          break;
        case AxonifyExceptionCode.CONTRACT_ERROR_VALUE_TOO_LONG:
          error = I18n.t('discover.pageAccess.community.error.2008');
          shouldNavigate = false;
          break;
        default:
          xhr.skipGlobalHandler = false;
      }
    }

    // If it's an error caught above, show the flash, otherwise let the global handler take it.
    if (error) {
      window.app.layout.flash.error(error);
    }
    if (shouldNavigate) {
      Backbone.history.navigate('#hub/search/communitiesManagement/communities/1', true);
    }
  }

  _save() {
    // Soft cache the old code so it will persist if there's an error on save.
    this.originalCommunityCode = this.model.get('originalCode');
    // Send the original code if the user deletes it and leaves it blank on edit.
    if (!this.model.get('code') && this.originalCommunityCode) {
      this.model.set('code', this.originalCommunityCode);
    }

    const currentTabView = this.findControllerByRegion('tabsRegion').getCurrentTabView();

    const errors = [];
    if (currentTabView != null && currentTabView.form != null) {
      const tabFormErrors = currentTabView.form.commit();
      if (tabFormErrors != null) {
        errors.push(tabFormErrors);
      }
      if (errors.length === 0 && typeof currentTabView.setLocalizableDefaults === 'function') {
        currentTabView.setLocalizableDefaults();
      }
    }

    // If an admin is trying to create a new community with all content disabled, show an error.
    // We allow this to happen on edit because sometimes admins like to prevent content from being added to a community
    // after it's been created, but still allow the existing community content to be viewed.
    if (this.communityId == null
      && !this.model.get('articlesEnabled')
      && !this.model.get('postsEnabled')
      && !this.model.get('questionsEnabled')) {
      errors.push(I18n.t('communitiesManagement.error.allContentDisabled'));
    }

    if (errors != null && errors.length > 0) {
      window.app.layout.flash.error(errors);
    } else {
      window.app.layout.showSpinner(true);
      this.model.save()
        .then(() => {
          window.app.layout.hideSpinner();
          window.app.layout.flash.success( I18n.t('communities.saved'));
          this._navigateBack();
        }, this._onCommunityError);
    }
  }

  _navigateBack() {
    Backbone.history.history.back();
  }

  _getTabConfigs() {
    const universalSearchEnabled = TenantPropertyProvider.get().getProperty('universalSearchEnabled');
    const integrationTab = {
      tab: {
        tabName: CommunityManagementTabsEnum.INTEGRATION,
        tabText: I18n.t('communitiesManagement.community.tabs.integration')
      },
      tabContentController: {
        ViewControllerClass: IntegrationController,
        id: 'integration-tab',
        context: this.context
      }
    };

    this.tabs = [
      {
        tab: {
          tabName: CommunityManagementTabsEnum.GENERAL_SETTINGS,
          tabText: I18n.t('communitiesManagement.community.tabs.generalSettings')
        },
        tabContentController: {
          viewDefinition: {
            ViewClass: GeneralSettingsTabView,
            model: this.model
          }
        }
      },
      {
        tab: {
          tabName: CommunityManagementTabsEnum.ACCESS_SETTINGS,
          tabText: I18n.t('communitiesManagement.community.tabs.accessSettings')
        },
        tabContentController: {
          viewDefinition: {
            ViewClass: AccessSettingsTabView,
            model: this.model,
            groupList: this.groupList,
            teamsList: this.teamsList
          }
        }
      },
      {
        tab: {
          tabName: CommunityManagementTabsEnum.CONTENT_SETTINGS,
          tabText: I18n.t('communitiesManagement.community.tabs.contentSettings')
        },
        tabContentController: {
          id: 'content-settings-tab',
          viewDefinition: {
            ViewClass: ContentSettingsLayout,
            model: this.model
          }
        }
      }
    ];

    if (universalSearchEnabled && this.communityId) {
      this.tabs.push(integrationTab);
    }
    return this.tabs;
  }
}


module.exports = CommunityManagementController;
