const I18n = require('@common/libs/I18n');

const { Question } = require('@training/apps/training/assessmentProgress/ProgressMarkerOptions');

const AbstractProgressConfig = require('@training/apps/training/assessmentProgress/AbstractProgressConfig');

class AssessmentQuizProgressConfig extends AbstractProgressConfig {

  static ProgressBarConfigId = 'quiz-progress-bar';

  static MarkerConfigId = 'quiz-marker';

  constructor(assessment) {
    super();

    this.assessment = assessment;
  }

  isValid() {
    return this.assessment.activities.getTotalQuizActivityCount() > 0;
  }

  getSegmentLabelText() {
    const totalQuestionCount = this.assessment.activities.getTotalQuizActivityCount();

    if (totalQuestionCount === 1) {
      return I18n.t('assessments.progress.segment.questions.singular');
    }
    return I18n.t('assessments.progress.segment.questions.plural', {count: totalQuestionCount});

  }

  getSegmentValue() {
    const totalQuestionCount = this.assessment.activities.getTotalQuizActivityCount(this.assessment);
    const completedQuestionCount = this.assessment.activities.getClosedQuizActivityCount(this.assessment);

    return completedQuestionCount / totalQuestionCount * 100;
  }

  getProgressBarOptions() {
    return {
      id: AssessmentQuizProgressConfig.ProgressBarConfigId,
      labelText: this.getSegmentLabelText(),
      lineCount: this.assessment.activities.getTotalQuizActivityCount(this.assessment),
      value: this.getSegmentValue()
    };
  }

  getMarkerOptions() {
    // If the currently active activity is not a question, return a blank marker. Otherwise, return the question marker.
    if (this.assessment.activities.nextActivity() && !this.assessment.activities.nextActivity().isQuestionContentActivity()) {
      return {};
    }
    return Question;
  }

  containsActiveActivity() {
    if (!this.isValid()) {
      return false;
    }
    const nextActivity = this.assessment.activities.nextActivity();
    if (!nextActivity) {
      // If there is no next activity (while still being valid), then the quiz must be complete which counts as active
      return true;
    }
    return nextActivity.isQuestionContentActivity();
  }
}

module.exports = AssessmentQuizProgressConfig;
